import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import icon from "../../../constants/icon";
import { ICombo } from "../../../interface/combo";
import { IProduct } from "../../../interface/product";
import { IServiceItem } from "../../../interface/serviceItem";
import { addCart } from "../../../redux/cartSlice";
import { onError } from "../../../utils/cusFunction";
import formatPrice from "../../../utils/formatPrice";
export default function DialogComboDetail(props: any) {
    const { open, onClose, item } = props;
    const dispatch = useDispatch();
    const orgState = useSelector(
        (state: any) => state.organization.organizationInit
    );
    const [quantity, setQuantity] = useState(1);
    const handleAddCard = (combo: ICombo) => {
        const cartItem = {
            ...combo,
            cart_id: parseInt(`3${combo.id}`),
            quantity: quantity,
            price_cart: combo.price,
        };
        const action = addCart(cartItem);
        handleOnClose();
        dispatch(action);
    };
    const handleDecCart = () => {
        if (quantity === 1) {
            setQuantity(1);
        } else setQuantity(quantity - 1);
    };
    const handleIncCart = () => {
        setQuantity(quantity + 1);
    };
    const handleOnClose = () => {
        if (onClose) {
            onClose();
            setQuantity(1);
        }
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="dialog-detail__combo">
                <div onClick={onClose} className="dialog-detail__close">
                    <img src={icon.increase} alt="" />
                </div>

                <div className="detail-content">
                    <div className="detail-top">
                        {/* <div className="detail-expiry">
                            <p>
                                HSD: Còn {item.expired.replace("-d", "")} ngày
                            </p>
                        </div> */}
                        <div className="detail-img">
                            <img
                                src={
                                    item.image_url
                                        ? item.image_url
                                        : orgState.image_url
                                }
                                onError={(e) => {
                                    onError(e);
                                }}
                                alt=""
                            />
                        </div>
                        <div className="flex-column">
                            <div className="detail-name">
                                <p>{item.name}</p>
                            </div>
                            <div className="flex-column">
                                {item.special_price ? (
                                    <>
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                color: "var(--red",
                                                fontSize: "14px",
                                            }}
                                        >
                                            {`${formatPrice(
                                                parseInt(item.special_price)
                                            )}`}
                                            đ
                                        </span>
                                        <span
                                            style={{
                                                textDecoration: "line-through",
                                                color: "gray",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {`${formatPrice(
                                                parseInt(item.price)
                                            )}`}
                                            đ
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "14px",
                                                color: "var(--purple) ",
                                            }}
                                        >
                                            {`${formatPrice(
                                                parseInt(item.price)
                                            )}`}
                                            đ
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* service list */}
                    {item?.services.length > 0 ? (
                        <>
                            <div
                                style={{ marginTop: "8px", fontWeight: "bold" }}
                                className="service__list "
                            >
                                <p>Dịch vụ ({item.services.length})</p>
                                {item?.services?.map(
                                    (item: IServiceItem, i: number) => (
                                        <div className="service__item" key={i}>
                                            <div className="service-item__img">
                                                <img
                                                    src={
                                                        item?.image_url
                                                            ? item?.image_url
                                                            : orgState?.image_url
                                                    }
                                                    onError={(e) => {
                                                        onError(e);
                                                    }}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="service-item__content">
                                                <div className="item-name">
                                                    {item?.service_name}
                                                </div>
                                                <div className="flex-row gap-row-4">
                                                    <img
                                                        style={{
                                                            width: "16px",
                                                            height: "16px",
                                                        }}
                                                        src={icon.tag}
                                                        alt=""
                                                    />
                                                    <div className="item-price">
                                                        {formatPrice(
                                                            item?.price
                                                        )}
                                                        đ
                                                    </div>
                                                </div>
                                                <div className="flex-row gap-row-4">
                                                    <img
                                                        src={icon.clock}
                                                        alt=""
                                                    />
                                                    <div
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                        className="item-duration"
                                                    >
                                                        {item?.duration} phút
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </>
                    ) : null}
                    {/* product list */}
                    {item?.products.length > 0 ? (
                        <>
                            <div
                                style={{ marginTop: "8px", fontWeight: "bold" }}
                                className="service__list "
                            >
                                <p>Sản phẩm({item.products.length})</p>
                                {item?.products?.map(
                                    (item: IProduct, i: number) => (
                                        <div className="service__item" key={i}>
                                            <div className="service-item__img">
                                                <img
                                                    src={
                                                        item?.image_url
                                                            ? item?.image_url
                                                            : orgState?.image_url
                                                    }
                                                    onError={(e) => {
                                                        onError(e);
                                                    }}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="service-item__content">
                                                <div className="item-name">
                                                    {item?.product_name}
                                                </div>
                                                <div className="flex-row gap-row-4">
                                                    <img
                                                        style={{
                                                            width: "16px",
                                                            height: "16px",
                                                        }}
                                                        src={icon.tag}
                                                        alt=""
                                                    />
                                                    <div className="item-price">
                                                        {formatPrice(
                                                            item?.retail_price
                                                        )}
                                                        đ
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </>
                    ) : null}
                </div>

                <div className="content-addcart">
                    <div className="item-amount">
                        <div
                            onClick={() => {
                                handleDecCart();
                            }}
                            className="amount-de"
                        >
                            <img src={icon.decrease} alt="" />
                        </div>
                        <span>{quantity}</span>
                        <div
                            onClick={() => {
                                handleIncCart();
                            }}
                            className="amount-ce"
                        >
                            <img src={icon.increase} alt="" />
                        </div>
                    </div>
                    <div
                        onClick={() => handleAddCard(item)}
                        className="btn-addcart"
                    >
                        <p>Thêm vào giỏ hàng</p>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
