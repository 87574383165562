import { Dialog } from "@mui/material";
import React from "react";
import img from "../../constants/img";
import ButtonCart from "../ButtonCart/ButtonCart";

export default function DialogAddSuccess(props: any) {
    const { open, onClose, item } = props;
    return (
        <>
            <ButtonCart />
            <Dialog open={open} onClose={onClose}>
                <div className="dialog-alert">
                    <div className="alert__img">
                        <img src={img.confirm} alt="" />
                    </div>
                    <p className="title-alert">Thành công!</p>

                    <span className="title">
                        Đã thêm{" "}
                        <span
                            style={{
                                textTransform: "capitalize",
                                color: "var(--purple",
                            }}
                        >
                            {item?.product_name ||
                            item?.service_name ||
                            item?.name
                                ? item?.product_name ||
                                  item?.service_name ||
                                  item?.name
                                : item?.description}
                        </span>{" "}
                        vào giỏ hàng
                    </span>

                    <div
                        onClick={() => {
                            onClose();
                        }}
                        className="btn-delete"
                    >
                        <p>Đóng</p>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
