import axiosClient from "./axiosClient";
class Order {
    postOder = (values: any, userToken: string) => {
        const url = `/organizations/${values.org_id}/orders`;
        const params = {
            products: values.products,
            services: values.services,
            treatment_combo: values.combos,
            coupon_code: [],
            payment_method_id: 9,
        };
        return axiosClient.post(url, params, {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        });
    };
}
const orderApi = new Order();
export default orderApi;
