import { Container, Skeleton } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import appointmentAPI from "../../api/Appointment";
import Header from "../../components/Header/Header";
import img from "../../constants/img";
import "./checkin.scss";
import CheckinItem from "./Components/CheckinItem";
interface IDataAppoint {
    appoint: [];
    loading: boolean;
}
export default function Checkin() {
    const title = "Thông tin đặt hẹn";
    const checkinStore = useSelector((state: any) => state.checkin);
    const user = useSelector((state: any) => state.authen.authInit);
    const today = new Date();
    const idOrg = useSelector(
        (state: any) => state.organization.organizationInit.id
    );
    let date = moment(today).format("YYYY-MM");
    const history = useHistory();
    const handleMoveService = () => {
        history.push(`./Service`);
    };
    const [render, setRender] = useState(false);
    const [data, setData] = useState<IDataAppoint>({
        appoint: [],
        loading: true,
    });
    useEffect(() => {
        const getAppointMent = async (date: string, user: string) => {
            try {
                const res = await appointmentAPI.getAppoint(date, user);
                const filterOrg = res?.data.context.data.filter((item: any) => {
                    return item?.organization_id === idOrg;
                });
                setData({
                    appoint: filterOrg,
                    loading: false,
                });
            } catch (error) {
                console.log(error);
                setData({ ...data, loading: false });
            }
        };
        setData({ ...data, loading: true });
        getAppointMent(date, user);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, user]);

    return (
        <div className="checkin">
            <Header title={title} />
            <Container>
                <div className="checkin-wrap">
                    <div className="checkin-list">
                        {data.loading === false ? (
                            <>
                                {(
                                    data.appoint
                                        ? data.appoint.length === 0
                                        : data.appoint === undefined
                                ) ? (
                                    <div className="checkin-list__empty">
                                        <div className="list-empty__img">
                                            <img src={img.emptyCart} alt="" />
                                        </div>

                                        <p>Lịch hẹn trống vui lòng đặt hẹn</p>
                                        <p
                                            onClick={() => {
                                                handleMoveService();
                                            }}
                                            style={{
                                                marginTop: "16px",
                                            }}
                                            className="btn-green"
                                        >
                                            Đặt hẹn ngay
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        {data.appoint.map(
                                            (item: any, index: number) => (
                                                <CheckinItem
                                                    key={index}
                                                    item={item}
                                                    checkinStore={checkinStore}
                                                    setRender={setRender}
                                                    render={render}
                                                />
                                            )
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={118}
                                    />
                                </div>
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={118}
                                    />
                                </div>
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={118}
                                    />
                                </div>
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={118}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
}
