import { createSlice } from "@reduxjs/toolkit";
const storageName = "id-org";
const storage = JSON.parse(`${window.sessionStorage.getItem(storageName)}`);
const initialState = {
    organizationInit: window.sessionStorage.getItem(storageName) ? storage : {},
};
const organizations = createSlice({
    name: "organizations",
    initialState,
    reducers: {
        getIdOrganization: (state: any, action: any) => {
            state.organizationInit = action.payload;
            window.sessionStorage.setItem(
                storageName,
                JSON.stringify(state.organizationInit)
            );
        },
    },
});
const { reducer, actions } = organizations;
export const { getIdOrganization } = actions;
export default reducer;
