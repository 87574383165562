import { Container } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import icon from "../../constants/icon";
import DialogSearch from "./DialogSearch";
import "./header.scss";
export default function Header(props: any) {
    const { title, search = false } = props;
    const history = useHistory();
    const [openSearch, setOpenSearch] = React.useState(false);
    const handleCloseSearch = () => {
        setOpenSearch(false);
    };
    function handleClick() {
        history.push(`/Home`);
    }
    return (
        <div className="home-head__wrap">
            <Container>
                <header className="header">
                    <div
                        onClick={() => {
                            handleClick();
                        }}
                        className="header-back"
                    >
                        <img src={icon.arrowLeft} alt="" />
                    </div>
                    <div className="header-title">{title}</div>
                    {search === true && (
                        <>
                            <div
                                onClick={() => {
                                    setOpenSearch(true);
                                }}
                                className="header-search"
                            >
                                <img src={icon.search} alt="" />
                            </div>
                            <div className="header-search__input">
                                <input
                                    readOnly
                                    onClick={() => {
                                        setOpenSearch(true);
                                    }}
                                    type="text"
                                    placeholder="Tìm kiếm ... "
                                />
                                <img src={icon.search} alt="" />
                            </div>
                            <DialogSearch
                                open={openSearch}
                                onClose={handleCloseSearch}
                            />
                        </>
                    )}
                </header>
            </Container>
        </div>
    );
}
