import logOut from "../assets/icon/log-out.svg";
import deletes from "../assets/icon/delete.svg";
import arrowRight from "../assets/icon/arrow-right.svg";
import arrowLeft from "../assets/icon/arrow-left.svg";
import addCart from "../assets/icon/add-cart.svg";
import increase from "../assets/icon/increase.svg";
import decrease from "../assets/icon/decrease.svg";
import trash from "../assets/icon/trash.svg";
import tag from "../assets/icon/tag.svg";
import phone from "../assets/icon/phone.svg";
import mail from "../assets/icon/mail.svg";
import home from "../assets/icon/home.svg";
import user from "../assets/icon/user.svg";
import mapPin from "../assets/icon/map-pin.svg";
import location from "../assets/icon/location.svg";
import mapPinLocation from "../assets/icon/map-pin-location.svg";
import shoppingCartWhite from "../assets/icon/shopping-cart-white.svg";
import arrowRightPurple from "../assets/icon/arrrow-right-purple.svg";
import clock from "../assets/icon/clock.svg";
import search from "../assets/icon/search.svg";
import searchGray from "../assets/icon/searchGray.svg";
import x from "../assets/icon/closePurple.svg";
import Tumer from "../assets/icon/Tumer.svg";
import chevronLeftPurple from "../assets/icon/chevron-left-purple.svg";
import Alert from "../assets/icon/alert.svg";

const icon = {
    Alert,
    chevronLeftPurple,
    searchGray,
    x,
    Tumer,
    search,
    shoppingCartWhite,
    arrowRightPurple,
    location,
    mapPinLocation,
    clock,
    phone,
    mail,
    home,
    user,
    mapPin,
    tag,
    trash,
    increase,
    decrease,
    addCart,
    logOut,
    arrowLeft,
    deletes,
    arrowRight,
};
export default icon;
