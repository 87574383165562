import React, { useState } from "react";
import formatPrice from "../../../utils/formatPrice";
import DialogEndow from "./DialogEndow";
import { useDispatch, useSelector } from "react-redux";
import { IPromo } from "../../../interface/promo";
import { addPromo } from "../../../redux/promoSlice";
import DialogAlert from "../../../components/DialogAlert/DialogAlert";
import { onError } from "../../../utils/cusFunction";
import moment from "moment";
import { IPromoItem } from "../../../interface/promoItem";
import { IProductable } from "../../../interface/productable";

export default function EndowItem(props: any) {
    const { index, item } = props;
    const productable = item.items?.map(
        (item: IPromoItem) => item?.productable
    );
    const productableItem = productable?.map((item: IProductable) => item);
    const promoItem = item.items;
    const dataPromo = {
        promoItem: promoItem,
        productableItem: productableItem,
    };
    const dispatch = useDispatch();
    const dateEnd = moment(item?.valid_util).format("DD-MM-YYYY");
    const dateStart = moment(item?.valid_from).format("DD-MM-YYYY");
    // open Dialog Endow
    const [openDialogEndow, setOpenDialogEndow] = useState(false);
    // get state in redux
    const cartStore = useSelector((state: any) => state.carts.cartList);
    const [openDialogAlert, setOpenDialogAlert] = useState(false);
    const handleOpenDialogAlert = () => {
        setOpenDialogAlert(true);
    };
    const handleCloseDialogAlert = () => {
        setOpenDialogAlert(false);
    };
    const handleOpenDialogEndow = () => {
        setOpenDialogEndow(true);
    };
    const handleCloseDialogEndow = () => {
        setOpenDialogEndow(false);
    };

    const gotoCart = (promo: any) => {
        if (cartStore.length !== 0) {
            dispatch(addPromo(promo));
            // history.push({
            //     pathname: "./Service",
            //     search: "1",
            // });
            alert("Chức năng đang được xây dựng");
        } else {
            handleOpenDialogAlert();
        }
    };

    return (
        <>
            <div
                onClick={() => {
                    handleOpenDialogEndow();
                }}
                key={index}
                className="endow-item"
            >
                <div className="endow-item__img">
                    {productableItem.map(
                        (item: IProductable, index: number) => (
                            <img
                                key={index}
                                src={item?.image_url}
                                onError={(e) => {
                                    onError(e);
                                }}
                                alt=""
                            />
                        )
                    )}
                </div>
                <div className="endow-item__content">
                    <div className="item-name">{item.title}</div>

                    <div className="item-price">
                        {productableItem.map(
                            (item: IProductable, index: number) => (
                                <p key={index}>{formatPrice(item.price)}đ</p>
                            )
                        )}
                    </div>

                    <div className="item-total">
                        <div className="item-wrap">
                            {item.items.map(
                                (item: IPromoItem, index: number) => (
                                    <p className="item-special" key={index}>
                                        {formatPrice(item.view_price)}đ
                                    </p>
                                )
                            )}
                            {item?.valid_util === null ? (
                                <p>HSD: Không thời hạn</p>
                            ) : (
                                <p>HSD: {dateEnd}</p>
                            )}
                        </div>
                        <div
                            onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                gotoCart(dataPromo);
                            }}
                            className="button-apply"
                        >
                            <p>Sử dụng</p>
                        </div>
                    </div>
                </div>
            </div>
            <DialogEndow
                open={openDialogEndow}
                onClose={handleCloseDialogEndow}
                item={item}
                dateEnd={dateEnd}
                dateStart={dateStart}
                productable={productable}
                productableItem={productableItem}
                dataPromo={dataPromo}
            />
            <DialogAlert
                open={openDialogAlert}
                onClose={handleCloseDialogAlert}
                title={`Vui lòng thêm sản phẩm, dịch vụ, combo vào giỏ hàng để sử dụng ưu đãi.`}
                url={`/Service`}
                titleClose={`Thêm ngay`}
                buttonClose
            />
        </>

        // <>
        //     {item.quantity < 1 ||
        //     dateEndFormat.toString() < dateNow.toString() ||
        //     dateStartFormat.toString() > dateNow.toString() ? (
        //         <div
        //             style={{
        //                 position: "relative",
        //             }}
        //         >
        //             <div className="endow-overlay">
        //                 <div className="endow-overlay__title">
        //                     {item.quantity < 1 ? (
        //                         <>
        //                             <p>Đã hết lượt sử dụng</p>
        //                         </>
        //                     ) : (
        //                         ""
        //                     )}

        //                     {dateEndFormat.toString() < dateNow.toString() ? (
        //                         <>
        //                             <p>Ưu đãi kết thúc</p>
        //                         </>
        //                     ) : (
        //                         <></>
        //                     )}
        //                     {dateStartFormat.toString() > dateNow.toString() ? (
        //                         <>
        //                             <p
        //                                 style={{
        //                                     width: "100%",
        //                                     textAlign: "center",
        //                                     display: "inline-block",
        //                                 }}
        //                             >
        //                                 Ưu đãi bắt đầu từ <br /> {sortDateStart}{" "}
        //                                 {sortTimeStart}
        //                             </p>
        //                         </>
        //                     ) : (
        //                         ""
        //                     )}
        //                 </div>
        //             </div>
        //             <div key={index} className="endow-item">
        //                 <div className="endow-item__img">
        //                     <img src={item?.image} alt="" />
        //                 </div>
        //                 <div className="endow-item__content">
        //                     <div className="item-name">{item.name}</div>
        //                     <div className="item-coupon ">
        //                         <p>
        //                             Giảm:{" "}
        //                             {`${formatPrice(item.discount_value)}`}
        //                             {item.discount_unit}
        //                         </p>
        //                         <Rating
        //                             name="read-only"
        //                             value={valueRating}
        //                             readOnly
        //                         />
        //                     </div>
        //                     <div className="item-title">{item.description}</div>
        //                     <p
        //                         style={{ color: "black" }}
        //                         className="item-title"
        //                     >
        //                         Đã sử dụng: {item.remain_times}
        //                     </p>
        //                     <div className="item-total">
        //                         <p>Còn lại: {item.quantity}</p>
        //                         <div className="button-apply">
        //                             <p>Sử dụng</p>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     ) : (
        //         <div
        //             onClick={() => {
        //                 handleOpenDialogEndow();
        //             }}
        //             key={index}
        //             className="endow-item"
        //         >
        //             <div className="endow-item__img">
        //                 <img
        //                     src={item.image ? item.image : img.logoMyspa}
        //                     onError={(e) => {
        //                         onError(e);
        //                     }}
        //                     alt=""
        //                 />
        //             </div>
        //             <div className="endow-item__content">
        //                 <div className="item-name">{item.name}</div>
        //                 <div className="item-coupon">
        //                     <p>
        //                         Giảm: {`${formatPrice(item.discount_value)}`}
        //                         {item.discount_unit}
        //                     </p>
        //                     <Rating
        //                         name="read-only"
        //                         value={valueRating}
        //                         readOnly
        //                     />
        //                 </div>
        //                 <div className="item-title">{item.description}</div>
        //                 <p style={{ color: "black" }} className="item-title">
        //                     Đã sử dụng: {item.remain_times}
        //                 </p>
        //                 <div className="item-total">
        //                     <div>
        //                         <p>Còn lại: {item.quantity}</p>
        //                     </div>
        //                     <div
        //                         onClick={(e: any) => {
        //                             e.stopPropagation();
        //                             e.preventDefault();
        //                             gotoCart(item);
        //                         }}
        //                         className="button-apply"
        //                     >
        //                         <p>Sử dụng</p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     )}
        //     <DialogEndow
        //         open={openDialogEndow}
        //         onClose={handleCloseDialogEndow}
        //         item={item}
        //         sortDateStart={sortDateStart}
        //         sortTimeStart={sortTimeStart}
        //         sortDateEnd={sortDateEnd}
        //         sortTimeEnd={sortTimeEnd}
        //     />
        //     <DialogAlert
        //         open={openDialogAlert}
        //         onClose={handleCloseDialogAlert}
        //         title={`Vui lòng thêm sản phẩm, dịch vụ, combo vào giỏ hàng để sử dụng ưu đãi.`}
        //         url={`/Service`}
        //         titleClose={`Thêm ngay`}
        //         buttonClose
        //     />
        // </>
    );
}
