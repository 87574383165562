import { Container } from "@mui/material";
import Header from "../../components/Header/Header";
import "./evaluate.scss";
import { useState } from "react";
import DialogConfilm from "./Components/DialogConfirm";
import img from "../../constants/img";

import DialogAlert from "../../components/DialogAlert/DialogAlert";
import { useSelector } from "react-redux";
export default function Evaluate(props: any) {
    const title = "Đánh giá";
    const org = useSelector(
        (state: any) => state.organization.organizationInit
    );
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openConfirmed, setOpenConfirmed] = useState(false);
    const [openCheckPhone, setOpenCheckPhone] = useState(false);
    const [openCheckAppoint, setOpenCheckAppoint] = useState(false);
    const subDomain = useSelector(
        (state: any) => state.subDomain.subDoMainInit
    );
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };
    const handleCloseConfirmed = () => {
        setOpenConfirmed(false);
    };
    const handleCloseCheckPhone = () => {
        setOpenCheckPhone(false);
    };
    const handleCloseCheckAppoint = () => {
        setOpenCheckAppoint(false);
    };

    return (
        <div className="evaluate">
            <Header title={title} />
            <Container>
                <div className="evaluate-wrapper">
                    <div className="evaluate-img">
                        <img
                            src={org.image_url ? org.image_url : img.avatar}
                            alt=""
                        />
                    </div>
                    <div className="evaluate-title">
                        <h1 style={{ lineHeight: "1.5" }}>
                            Cảm ơn đã lựa chọn làm đẹp với {org.name} nếu hài
                            lòng hãy đánh giá cho chúng tôi
                        </h1>
                        <h3>
                            Myspa không ngừng lắng nghe và cải thiện chất lượng
                            nhằm mang lại dịch vụ tốt nhất cho bạn. Vui lòng để
                            lại đánh giá nhé!
                        </h3>
                    </div>

                    <div className="evaluate-rating"></div>

                    <div className="evaluate-input">
                        <input
                            type="text"
                            name="text"
                            placeholder="Góp ý với chúng tôi..."
                        />
                    </div>

                    <div
                        onClick={() => {
                            setOpenConfirm(true);
                        }}
                        className="evaluate-btn"
                    >
                        <p>Gửi phản hồi</p>
                    </div>
                </div>
            </Container>

            <DialogConfilm
                openConfirm={openConfirm}
                onClose={handleCloseConfirm}
                title={`Cảm ơn quý khách`}
                content={`Mỗi đánh giá của khách hàng giúp chúng tôi cải thiện tốt hơn.`}
                imageSuccess
            />
            <DialogAlert
                open={openConfirmed}
                onClose={handleCloseConfirmed}
                title={`Bạn đã đánh giá lượt làm đẹp này`}
                titleClose={`Về trang chủ`}
                buttonClose
                // imageSuccess
                url={`/Home/${subDomain}`}
            />
            <DialogAlert
                open={openCheckPhone}
                onClose={handleCloseCheckPhone}
                title={`Vui lòng nhập số điện thoại và Check-in để thực hiện chức năng này`}
                titleClose={`Về trang chủ`}
                url={`/Home/${subDomain}`}
                buttonClose
            />
            <DialogAlert
                open={openCheckAppoint}
                onClose={handleCloseCheckAppoint}
                title={`Bạn chưa có lịch hẹn nào`}
                titleClose={`Đặt hẹn ngay`}
                url={`/Service`}
                buttonClose
            />
        </div>
    );
}
