import axiosClient from "./axiosClient";

interface IValues {
    telephone: string;
    subdomain: string;
    verification_id: string;
    code: string;
}

class Authen {
    postAuthen = (values: IValues) => {
        const params = {
            telephone: values.telephone,
            // code: values.code,
            // verification_id: values.verification_id,
        };
        const url = `/auth/${values.subdomain}/checkin`;
        return axiosClient.post(url, params);
    };
}
const authenApi = new Authen();
export default authenApi;
