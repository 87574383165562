import axiosClient from "./axiosClient";
class Appointment {
    getAppoint = (date: string, user: any) => {
        // const token = `17734 | weQ7Fxdogl7zYnWnpVsVxytFEirw1ulUq4zXzG5z`;
        const url = `/appointments`;
        const params = {
            page: "1",
            limit: "300",
            "filter[time_start]": date,
            "filter[platform]": "MYSPA CHECKIN",
            append: "services",
            sort: "-time_start",
        };
        return axiosClient.get(url, {
            params,
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        });
    };
    postAppointMent = (values: any, user: any) => {
        const url = `/organizations/${values.org_id}/appointments`;
        const params = {
            service_ids: values.services,
            branch_id: values.org_id,
            time_start: values.time_start,
            order_id: values.order_id,
        };
        return axiosClient.post(url, params, {
            headers: {
                Authorization: `Bearer ${user}`,
            },
        });
    };
    // putCheckin = (id: string) => {
    //     const url = `v1/Appointment/checkin`;
    //     const params = {
    //         apt_id: id,
    //     };
    //     return axiosClient.put(url, params, {
    //         headers: {
    //             "user-token": `0P9QXPTJIAERDH1YYT7B4YMUCVM5FA9YPZKSXEE5C72TG04YWB8FU3EIIP0RX8MRSG8TW2AJRN2WSY6JMRCWXXL359K8U71I7JV8ENPBKOMLJMSP7R2UFTY358009I3D`,
    //         },
    //     });
    // };
    // postOrder = (params: any) => {
    //     const url = `/v1/Appointment/booking`;
    //     return axiosClient.post(url, params, {
    //         headers: {
    //             "user-token": `0P9QXPTJIAERDH1YYT7B4YMUCVM5FA9YPZKSXEE5C72TG04YWB8FU3EIIP0RX8MRSG8TW2AJRN2WSY6JMRCWXXL359K8U71I7JV8ENPBKOMLJMSP7R2UFTY358009I3D`,
    //         },
    //     });
    // };
    // deletePostOrder = (id: string) => {
    //     const url = `/v1/Appointment/remove?id=${id}`;
    //     const config = {
    //         id,
    //         headers: {
    //             "user-token": `0P9QXPTJIAERDH1YYT7B4YMUCVM5FA9YPZKSXEE5C72TG04YWB8FU3EIIP0RX8MRSG8TW2AJRN2WSY6JMRCWXXL359K8U71I7JV8ENPBKOMLJMSP7R2UFTY358009I3D`,
    //         },
    //     };
    //     return axiosClient.delete(url, config);
    // };
}
const appointmentAPI = new Appointment();
export default appointmentAPI;
