import { createSlice } from "@reduxjs/toolkit";
const storageName = "sub-domain";
const storage = JSON.parse(`${window.sessionStorage.getItem(storageName)}`);
const initialState = {
    subDoMainInit: window.sessionStorage.getItem(storageName) ? storage : null,
};
const subDoMain = createSlice({
    name: "SubDoMain",
    initialState,
    reducers: {
        getSubDoMain: (state, action) => {
            state.subDoMainInit = action.payload;
            if (state.subDoMainInit !== null) {
                window.sessionStorage.setItem(
                    storageName,
                    JSON.stringify(state.subDoMainInit)
                );
            }
        },
    },
});
const { reducer, actions } = subDoMain;
export const { getSubDoMain } = actions;
export default reducer;
