/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import icon from "../../constants/icon";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _, { debounce } from "lodash";
import organizationAPI from "../../api/Organization";
import { IService } from "../../interface/service";
import { IProduct } from "../../interface/product";
import { addCart } from "../../redux/cartSlice";
import DialogAddSuccess from "./DialogAddSuccess";
import { IServiceItem } from "../../interface/serviceItem";
import DialogServiceDetail from "../../features/Service/Components/DialogServiceDetail";
import DialogProductDetail from "../../features/Service/Components/DialogProductDetail";
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

interface IResult {
    services: IService[];
    products: IProduct[];
}

const DialogSearch = (props: any) => {
    const { onClose, open } = props;
    const dispatch = useDispatch();
    const org: any = useSelector(
        (state: any) => state.organization.organizationInit
    );
    const [result, setResultSearch] = useState<IResult>({
        services: [],
        products: [],
    });
    const [keyWord, setKeyWord] = useState("");
    const theme = useTheme();
    const querySM = useMediaQuery(theme.breakpoints.down("sm"));
    const [openDialogAlertAdd, setOpenDialogAlertAdd] = useState(false);
    const handleCloseDialogAdd = () => {
        setOpenDialogAlertAdd(false);
    };
    const [serviceDetail, setServiceDetail] = useState<IServiceItem[]>([]);
    const [productDetail, setProductDetail] = useState<IProduct[]>([]);
    const [openDialogDetailService, setOpenDialogDetailService] =
        useState(false);
    const [openDialogDetailProduct, setOpenDialogDetailProduct] =
        useState(false);
    const [itemAdd, setItemAdd] = useState();

    const handleCloseDialogDetailService = () => {
        setOpenDialogDetailService(false);
    };
    const handleCloseDialogDetailProduct = () => {
        setOpenDialogDetailProduct(false);
    };

    async function getItemByKeyword(keyword: string) {
        try {
            const values = {
                page: 1,
                keyword: keyword,
            };
            const res_services = await organizationAPI.getServiceByKeyword(
                org.id,
                values
            );
            const res_products = await organizationAPI.getProductByKeyword(
                org.id,
                values
            );
            setResultSearch({
                services: res_services?.data.context.data,
                products: res_products?.data.context.data,
            });
        } catch (error) {
            console.log(error);
        }
    }

    const debounceService = useCallback(
        debounce((nextValue) => getItemByKeyword(nextValue), 500),
        []
    );

    const handleAddCart = (item: any) => {
        if (item.service_name) {
            const cartItem = {
                ...item,
                cart_id: parseInt(`2${item.id}`),
                quantity: 1,
                price_cart: item.special_price
                    ? item.special_price
                    : item.price,
                type: "SERVICE",
            };
            const action = addCart(cartItem);
            dispatch(action);
            setOpenDialogAlertAdd(true);
            setItemAdd(item);
        } else if (item.product_name) {
            const cartItemPro = {
                ...item,
                cart_id: parseInt(`1${item.id}`),
                quantity: 1,
                price_cart: item?.special_price
                    ? item?.special_price
                    : item?.retail_price,
                type: "PRODUCT",
            };
            const action = addCart(cartItemPro);
            dispatch(action);
            setOpenDialogAlertAdd(true);
            setItemAdd(item);
        }
    };

    const handleChange = (e: any) => {
        const key = e.target.value;
        debounceService(key);
        setKeyWord(key);
    };

    const handleClickDetail = (item: any) => {
        if (item.service_name) {
            setServiceDetail(item);
            setOpenDialogDetailService(true);
        } else {
            setProductDetail(item);
            setOpenDialogDetailProduct(true);
        }
    };

    return (
        <Dialog
            fullScreen={querySM}
            TransitionComponent={querySM ? Transition : undefined}
            open={open}
            onClose={onClose}
        >
            <div className="dialog-search">
                {/* btn close */}
                <div
                    onClick={() => {
                        onClose();
                    }}
                    className="dialog-detail__close"
                >
                    <img src={icon.increase} alt="" />
                </div>
                {/* header search */}
                <div className="dialog-search__header">
                    <div
                        onClick={() => {
                            onClose();
                        }}
                        className="dialog-search__back"
                    >
                        <img src={icon.arrowLeft} alt="" />
                    </div>
                    <div className="dialog-search__input">
                        <div className="icon-search">
                            <img src={icon.search} alt="" />
                        </div>
                        <input
                            value={keyWord}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            placeholder="Tìm kiếm sản phẩm, dịch vụ ... "
                        />

                        {keyWord.length !== 0 ? (
                            <>
                                <div
                                    onClick={() => {
                                        setKeyWord("");
                                    }}
                                    className="icon-x"
                                >
                                    <img src={icon.x} alt="" />
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
                {/* body search */}
                <div className="dialog-search__body">
                    {keyWord.trim().length === 0 ||
                    (result.products.length === 0 &&
                        result.services.length === 0) ? (
                        <>
                            <p
                                style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    marginTop: "8px",
                                }}
                            >
                                Không có kết quả
                            </p>
                        </>
                    ) : (
                        <>
                            {/* list item service*/}
                            {result.services.length !== 0 ? (
                                <>
                                    <div className="dialog-search__list">
                                        <p className="dialog-search__title">
                                            Dịch vụ
                                        </p>
                                        {keyWord.trim().length > 0 &&
                                        result.services.length > 0
                                            ? result.services.map(
                                                  (item: any, i: number) => (
                                                      <div
                                                          key={i}
                                                          className="dialog-search__item"
                                                          onClick={() => {
                                                              handleClickDetail(
                                                                  item
                                                              );
                                                          }}
                                                      >
                                                          <div className="item-left">
                                                              <div className="item-left__img">
                                                                  <img
                                                                      src={
                                                                          item.image_url
                                                                              ? item.image_url
                                                                              : org.image_url
                                                                      }
                                                                      alt=""
                                                                  />
                                                              </div>
                                                              <div className="item-left__title">
                                                                  {
                                                                      item.service_name
                                                                  }
                                                              </div>
                                                          </div>
                                                          <div
                                                              onClick={(e) => {
                                                                  e.preventDefault();
                                                                  e.stopPropagation();
                                                                  handleAddCart(
                                                                      item
                                                                  );
                                                              }}
                                                              className="item-right"
                                                          >
                                                              <img
                                                                  src={
                                                                      icon.increase
                                                                  }
                                                                  alt=""
                                                              />
                                                          </div>
                                                      </div>
                                                  )
                                              )
                                            : null}
                                    </div>
                                </>
                            ) : null}
                            {/* list item product */}
                            {result.products.length !== 0 ? (
                                <div className="dialog-search__list">
                                    <p className="dialog-search__title">
                                        Sản phẩm
                                    </p>
                                    {keyWord.trim().length > 0 &&
                                    result.products.length > 0
                                        ? result.products.map(
                                              (item: any, i: number) => (
                                                  <div
                                                      key={i}
                                                      className="dialog-search__item"
                                                      onClick={() => {
                                                          handleClickDetail(
                                                              item
                                                          );
                                                      }}
                                                  >
                                                      <div className="item-left">
                                                          <div className="item-left__img">
                                                              <img
                                                                  src={
                                                                      item.image_url
                                                                          ? item.image_url
                                                                          : org.image_url
                                                                  }
                                                                  alt=""
                                                              />
                                                          </div>
                                                          <div className="item-left__title">
                                                              {
                                                                  item.product_name
                                                              }
                                                          </div>
                                                      </div>
                                                      <div className="item-right">
                                                          <img
                                                              onClick={(e) => {
                                                                  e.preventDefault();
                                                                  e.stopPropagation();
                                                                  handleAddCart(
                                                                      item
                                                                  );
                                                              }}
                                                              src={
                                                                  icon.increase
                                                              }
                                                              alt=""
                                                          />
                                                      </div>
                                                  </div>
                                              )
                                          )
                                        : null}
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
            <DialogAddSuccess
                open={openDialogAlertAdd}
                onClose={handleCloseDialogAdd}
                item={itemAdd}
            />
            <DialogServiceDetail
                item={serviceDetail}
                open={openDialogDetailService}
                onClose={handleCloseDialogDetailService}
            />
            <DialogProductDetail
                item={productDetail}
                open={openDialogDetailProduct}
                onClose={handleCloseDialogDetailProduct}
            />
        </Dialog>
    );
};

export default DialogSearch;
