import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    appointment: window.sessionStorage.getItem("appointment")
        ? JSON.parse(`${window.sessionStorage.getItem("appointment")}`)
        : [],
    customer: window.sessionStorage.getItem("customer")
        ? JSON.parse(`${window.sessionStorage.getItem("customer")}`)
        : {},
};
const checkin = createSlice({
    name: "checkin",
    initialState,
    reducers: {
        addCheckin: (state: any, action: any) => {
            state.appointment = action.payload.appointment || [];
            state.customer = action.payload.customer;
            window.sessionStorage.setItem(
                "appointment",
                JSON.stringify(state.appointment)
            );
            window.sessionStorage.setItem(
                "customer",
                JSON.stringify(state.customer)
            );
        },
        cleanCheckin: (state: any) => {
            state.appointment = [];
            state.customer = [];
            window.sessionStorage.setItem(
                "appointment",
                JSON.stringify(state.appointment)
            );
            window.sessionStorage.setItem(
                "customer",
                JSON.stringify(state.customer)
            );
        },
        removeItemCheckin: (state: any, action: any) => {
            const deleteItemCheckin = state.appointment.filter(
                (item: any) => item.id !== action.payload.id
            );
            state.appointment = deleteItemCheckin;
            window.sessionStorage.setItem(
                "appointment",
                JSON.stringify(deleteItemCheckin)
            );
        },
    },
});
const { reducer, actions } = checkin;
export const { addCheckin, cleanCheckin, removeItemCheckin } = actions;
export default reducer;
