import icon from "../../../constants/icon";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearAuth } from "../../../redux/auth";
import { clearAllCart } from "../../../redux/cartSlice";
import { clearAllPromo } from "../../../redux/promoSlice";

export default function HomeOption(props: any) {
    const { setOpenDialogOTP } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const SUBDOMAIN = useSelector(
        (state: any) => state.subDomain.subDoMainInit
    );
    const gotoService = () => {
        history.push("/Service");
    };
    const gotoEndow = () => {
        history.push("/Endow");
    };
    // const gotoEvaluate = () => {
    //     history.push("/Evaluate");
    // };
    const handleLogout = () => {
        history.push(`/Home/${SUBDOMAIN}`);
        dispatch(clearAuth());
        dispatch(clearAllCart());
        dispatch(clearAllPromo());
        window.recaptchaVerifier = null;
        setOpenDialogOTP(true);
    };

    return (
        <div className="home-content__option">
            <>
                <div
                    onClick={() => {
                        history.push({
                            pathname: `/Check-in`,
                        });
                    }}
                    className="option-item"
                >
                    <p>Check-in</p>
                    <img src={icon.arrowRight} alt="" />
                    <img
                        className="option-img"
                        src="https://picsum.photos/400/400?random=1"
                        alt=""
                    />
                </div>
            </>

            <div
                onClick={() => {
                    gotoService();
                }}
                className="option-item"
            >
                <p>Chọn dịch vụ</p>
                <img src={icon.arrowRight} alt="" />
                <img
                    className="option-img"
                    src="https://picsum.photos/400/400?random=2"
                    alt=""
                />
            </div>

            <div
                onClick={() => {
                    gotoEndow();
                }}
                className="option-item"
            >
                <p>Ưu đãi</p>
                <img src={icon.arrowRight} alt="" />
                <img
                    className="option-img"
                    src="https://picsum.photos/400/400?random=3"
                    alt=""
                />
            </div>

            <div
                onClick={() => {
                    handleLogout();
                    window.location.reload();
                }}
                className="option-item"
            >
                <p>Đổi số điện thoại</p>
                <img src={icon.arrowRight} alt="" />
                <img
                    className="option-img"
                    src="https://picsum.photos/400/400?random=4"
                    alt=""
                />
            </div>
            {/* <div
                onClick={() => {
                    gotoEvaluate();
                }}
                className="option-item"
            >
                <p>Đánh giá</p>
                <img src={icon.arrowRight} alt="" />
                <img
                    className="option-img"
                    src="https://picsum.photos/400/400?random=4"
                    alt=""
                />
            </div> */}
        </div>
    );
}
