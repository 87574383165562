import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import icon from "../../../constants/icon";
import { ICombo } from "../../../interface/combo";
import { addCart } from "../../../redux/cartSlice";
import formatPrice from "../../../utils/formatPrice";
import DialogComboDetail from "./DialogComboDetail";
import { onError } from "../../../utils/cusFunction";
import DialogAddSuccess from "../../../components/Header/DialogAddSuccess";

export default function TabComboItem(props: any) {
    const { item } = props;
    const orgState = useSelector(
        (state: any) => state.organization.organizationInit
    );
    const dispatch = useDispatch();
    const [openDialogDetail, setOpenDialogDetail] = useState(false);
    const handleClickOpenDialogDetail = () => {
        setOpenDialogDetail(true);
    };
    const [openDialogAddSuccess, setOpenDialogAddSuccess] = useState(false);
    const handleCloseDialogAddSuccess = () => {
        setOpenDialogAddSuccess(false);
    };
    const handleCloseDialogDetail = () => {
        setOpenDialogDetail(false);
    };
    const handleAddCart = (combo: ICombo) => {
        const cartComboItem = {
            ...combo,
            cart_id: parseInt(`3${combo.id}`),
            quantity: 1,
            price_cart: combo.price,
            type: "COMBO",
        };
        const action = addCart(cartComboItem);
        dispatch(action);
    };
    return (
        <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <div
                onClick={() => {
                    handleClickOpenDialogDetail();
                }}
                className="item-img"
            >
                <img
                    src={item?.image_url ? item?.image_url : orgState.image_url}
                    alt=""
                    onError={(e) => {
                        onError(e);
                    }}
                />
            </div>
            <div
                onClick={() => {
                    handleClickOpenDialogDetail();
                }}
                className="item-content"
            >
                <div className="item-expiry">
                    {/* {item.expired.replace("-d", "") > 0 ? (
                        <p>HSD: Còn {item.expired.replace("-d", "")} ngày</p>
                    ) : (
                        <p>HSD: không thời hạn</p>
                    )} */}
                </div>
                <div className="item-name">
                    <p>{item.name}</p>
                </div>
                <div className="item-footer">
                    <div className="item-total">
                        {/* <p>Gồm: {item.items.length} dịch vụ / sản phẩm</p> */}
                    </div>
                    <div className="item-bot">
                        <span
                            style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                            }}
                        >
                            {`${formatPrice(parseInt(item.price))}`}đ
                        </span>
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleAddCart(item);
                                setOpenDialogAddSuccess(true);
                            }}
                            className="item-bot__btn"
                        >
                            <img src={icon.increase} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <DialogComboDetail
                item={item}
                open={openDialogDetail}
                onClose={handleCloseDialogDetail}
            />
            <DialogAddSuccess
                open={openDialogAddSuccess}
                onClose={handleCloseDialogAddSuccess}
                item={item}
            />
        </div>
    );
}
