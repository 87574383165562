/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import icon from "../../../constants/icon";
import { decItem } from "../../../redux/cartSlice";
import { incItem } from "../../../redux/cartSlice";
import { onError } from "../../../utils/cusFunction";
import formatPrice from "../../../utils/formatPrice";
import DialogAlertDeleteItem from "./DialogAlertDeleteItem";

export default function TabServiceCartItem(props: any) {
    const { itemCart, promoStore } = props;
    const orgState = useSelector(
        (state: any) => state.organization.organizationInit
    );
    const dispatch = useDispatch();
    const [openDialogAlertDelete, setOpenDialogAlertDelete] = useState(false);

    const handleCloseDialogDelete = () => {
        setOpenDialogAlertDelete(false);
    };

    // const checkProSer = (promotion: any, item: any) => {
    //     return promotion?.items?.some((pro: any) => pro.id == item.id);
    // };

    // const calcDiscountItem = (promotion: any, item: any) => {
    //     let total = 0;
    //     if (promotion?.discount_unit == "%") {
    //         total =
    //             (promotion?.discount_value / 100) *
    //             (item.special_price > 0 ? item.special_price : item.price) *
    //             item.quantity;
    //     } else total = promotion?.discount_value * item.quantity;
    //     return total;
    // };

    // const calcDiscountItems = calcDiscountItem(promoStore, itemCart);

    const totalQuantity =
        parseInt(
            itemCart.special_price > 0
                ? itemCart.special_price
                : itemCart.price || itemCart.retail_price
        ) * parseInt(itemCart.quantity);

    const handleDecreaseCart = (item: any) => {
        const action = decItem(item);
        dispatch(action);
    };

    const handleIncreaseCart = (item: any) => {
        const action = incItem(item);
        dispatch(action);
    };

    const handleDeleteItemCart = (item: any) => {
        setOpenDialogAlertDelete(true);
        setItemDelete(item);
    };

    const [itemDelete, setItemDelete] = useState();

    return (
        <div className="item">
            <div className="item-info">
                <div className="flex-row">
                    <div className="item-img">
                        <img
                            src={
                                itemCart?.image_url
                                    ? itemCart?.image_url
                                    : orgState.image_url
                            }
                            onError={(e) => {
                                onError(e);
                            }}
                            alt=""
                        />
                    </div>

                    <div className="item-left">
                        <p>
                            {itemCart.service_name ||
                            itemCart.product_name ||
                            itemCart?.name
                                ? itemCart.service_name ||
                                  itemCart.product_name ||
                                  itemCart?.name
                                : itemCart.description}
                        </p>
                        <div className="item-price">
                            {itemCart.special_price > 0 ? (
                                <>
                                    <p>
                                        {`${formatPrice(
                                            itemCart.special_price
                                                ? itemCart.special_price
                                                : itemCart.price ||
                                                      itemCart.retail_price
                                        )}đ`}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p>{`${formatPrice(
                                        itemCart.price || itemCart.retail_price
                                    )}đ`}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex-column">
                    <div className="item-right">
                        <p>{`${formatPrice(totalQuantity)}đ`}</p>
                    </div>
                    {/* {promoStore &&
                        promoStore.discount_type == 2 &&
                        checkProSer(promoStore, itemCart) &&
                        calcDiscountItems && (
                            <>
                                <div className="item-right">
                                    <p
                                        style={{
                                            color: "var(--red)",
                                            marginTop: "4px",
                                        }}
                                    >{`- ${formatPrice(
                                        calcDiscountItems
                                    )}đ`}</p>
                                </div>
                            </>
                        )} */}
                </div>
            </div>

            <div className="item-option">
                <div className="item-amount">
                    <div
                        onClick={() => {
                            handleDecreaseCart(itemCart);
                        }}
                        className="amount-de"
                    >
                        <img src={icon.decrease} alt="" />
                    </div>
                    <span>{itemCart.quantity}</span>
                    <div
                        onClick={() => {
                            handleIncreaseCart(itemCart);
                        }}
                        className="amount-ce"
                    >
                        <img src={icon.increase} alt="" />
                    </div>
                </div>
                <div
                    onClick={() => {
                        handleDeleteItemCart(itemCart);
                    }}
                    className="item-delete"
                >
                    <img src={icon.trash} alt="" />
                </div>
            </div>
            <DialogAlertDeleteItem
                open={openDialogAlertDelete}
                onClose={handleCloseDialogDelete}
                item={itemDelete}
            />
        </div>
    );
}
