import axios from "axios";
import queryString from "query-string";
const axiosClient = axios.create({
    // baseURL: process.env.REACT_APP_API_DEV,
    baseURL: process.env.REACT_APP_API_URL,

    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "X-API-KEY": "myspa_api_key",
    },
    paramsSerializer: (params) => queryString.stringify(params),
});
axiosClient.interceptors.request.use(async (config) => {
    return config;
});
axios.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return response.data;
        }
        return response;
    },
    (error) => {
        throw error;
    }
);

export default axiosClient;
export const baseURL = process.env.REACT_APP_API_URL;
