import React from "react";

const tabList = [
    { id: 1, name: "Dịch vụ" },
    { id: 2, name: "Sản phẩm" },
    { id: 3, name: "Combo" },
];
export default function TabServiceTabList(props: any) {
    const { activeTab, chooseTabClick } = props;
    return (
        <div className="service-tab__list">
            {tabList.map((item, index) => (
                <div
                    style={
                        activeTab === item.id
                            ? {
                                  color: "var(--purple)",
                                  borderBottom: "2px solid var(--purple)",
                              }
                            : {
                                  color: "var(--black)",
                              }
                    }
                    onClick={() => chooseTabClick(item.id)}
                    key={index}
                    className="service-tab__item"
                >
                    <p>{item.name}</p>
                </div>
            ))}
        </div>
    );
}
