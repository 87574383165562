export const formatRequestBooking = (
    cart: Array<object>
): {
    service_ids: Array<ID>;
    product_ids: Array<ID>;
    treatment_ids: Array<ID>;
} => {
    let service_ids: any = [];
    let product_ids: any = [];
    let treatment_ids: any = [];
    cart.map((val: any) => {
        if (val?.type == "SERVICE")
            service_ids.push({
                id: +val?.id,
                quantity: +val?.quantity,
            });
        else if (val?.type == "PRODUCT")
            product_ids.push({
                id: +val?.id,
                quantity: +val?.quantity,
            });
        else
            treatment_ids.push({
                id: +val?.id,
                quantity: +val?.quantity,
            });
    });
    return { service_ids, product_ids, treatment_ids };
};
type ID = {
    id: string;
    quantity: string;
};
