import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cartSlice";
import promoReducer from "./promoSlice";
import checkinReducer from "./checkinSlice";
import organizationReducer from "./organization";
import authenReducer from "./auth";
import subDomainReducer from "./subDoMain";

const rootReducer = {
    carts: cartReducer,
    promo: promoReducer,
    checkin: checkinReducer,
    organization: organizationReducer,
    authen: authenReducer,
    subDomain: subDomainReducer,
};
const store = configureStore({
    reducer: rootReducer,
});
export default store;
