import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import icon from "../../../constants/icon";
import { IProduct } from "../../../interface/product";
import { addCart } from "../../../redux/cartSlice";
import { onError } from "../../../utils/cusFunction";
import formatPrice from "../../../utils/formatPrice";

export default function DialogProductDetail(props: any) {
    const { open, onClose, item } = props;
    const orgState = useSelector(
        (state: any) => state.organization.organizationInit
    );
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1);
    const handleAddCart = (product: IProduct) => {
        const cartProductItem = {
            ...product,
            cart_id: parseInt(`1${product.id}`),
            quantity: quantity,
            price_cart: product.special_price
                ? product.special_price
                : product.retail_price,
        };
        const action = addCart(cartProductItem);
        dispatch(action);
        handleClose();
    };

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        setQuantity(1);
    };

    const handleDecCart = () => {
        if (quantity === 1) {
            setQuantity(1);
        } else setQuantity(quantity - 1);
    };

    const handleIncCart = () => {
        setQuantity(quantity + 1);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <div className="dialog-detail">
                <div onClick={onClose} className="dialog-detail__close">
                    <img src={icon.increase} alt="" />
                </div>
                <div className="dialog-detail__img">
                    <img
                        src={
                            item?.image_url
                                ? item?.image_url
                                : orgState?.image_url
                        }
                        onError={(e) => {
                            onError(e);
                        }}
                        alt=""
                    />
                </div>
                <div className="dialog-detail__content">
                    <div>
                        {item?.product_name ? (
                            <>
                                <div className="content-name">
                                    {item?.product_name}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="content-name">
                                    {item?.description}
                                </div>
                            </>
                        )}
                        {item?.special_price !== -1 && item?.special_price ? (
                            <>
                                <div className="flex-column">
                                    <span
                                        style={{
                                            color: "var(--red",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {`${formatPrice(item?.special_price)}`}đ
                                    </span>
                                    <span
                                        style={{
                                            textDecoration: "line-through",
                                            color: "gray",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {`${formatPrice(item?.retail_price)}`}đ
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <span>Giá: </span>
                                <span
                                    style={{
                                        color: "var(--purple)",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {`${formatPrice(
                                        parseInt(item?.retail_price)
                                    )}`}
                                    đ
                                </span>
                            </>
                        )}

                        <p className="content-detail">
                            Mô tả:{" "}
                            {item?.description ? (
                                <>
                                    <span>{item?.description}</span>
                                </>
                            ) : (
                                <span>Đang cập nhật</span>
                            )}
                        </p>
                    </div>
                    <div className="content-addcart">
                        <div className="item-amount">
                            <div
                                onClick={() => {
                                    handleDecCart();
                                }}
                                className="amount-de"
                            >
                                <img src={icon.decrease} alt="" />
                            </div>
                            <span>{quantity}</span>
                            <div
                                onClick={() => {
                                    handleIncCart();
                                }}
                                className="amount-ce"
                            >
                                <img src={icon.increase} alt="" />
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                handleAddCart(item);
                            }}
                            className="btn-addcart"
                        >
                            <p>Thêm vào giỏ hàng</p>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
