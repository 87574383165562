import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { IPromo } from "../../interface/promo";
import EndowItem from "./Components/EndowItem";
import promoApi from "../../api/Promo";
import "./endow.scss";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";
interface IData {
    promo: IPromo[];
    loading: boolean;
}

export default function Endow() {
    const title = "Ưu đãi";
    const [data, setData] = useState<IData>({
        promo: [],
        loading: true,
    });
    const orgID = useSelector(
        (state: any) => state.organization.organizationInit.id
    );

    useEffect(() => {
        async function getPromo() {
            try {
                const res = await promoApi.getPromo(orgID);
                setData({
                    promo: res?.data.context.data,
                    loading: false,
                });
            } catch (error) {
                console.log(error);
                setData({ ...data, loading: false });
            }
        }
        getPromo();
        setData({ ...data, loading: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="endow">
            <Header title={title} />
            <Container>
                <div className="endow-wrapper">
                    {data.loading === true ? (
                        <div className="promo-skeleton">
                            <div className="promo-skeleton__item">
                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={"100%"}
                                />
                            </div>
                            <div className="promo-skeleton__item">
                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={"100%"}
                                />
                            </div>
                            <div className="promo-skeleton__item">
                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={"100%"}
                                />
                            </div>
                            <div className="promo-skeleton__item">
                                <Skeleton
                                    sx={{
                                        display: {
                                            xs: "block",
                                            sm: "none",
                                            lg: "block",
                                            xl: "block",
                                        },
                                    }}
                                    variant="rectangular"
                                    width={"100%"}
                                    height={"100%"}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            {data?.promo.length === 0 ? (
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    Không có ưu đãi
                                </p>
                            ) : (
                                <>
                                    <div className="promo-wrap">
                                        {data?.promo.map(
                                            (item: IPromo, index: number) => (
                                                <EndowItem
                                                    key={index}
                                                    item={item}
                                                />
                                            )
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
}
