import React, { useState } from "react";
import DialogCheckinDetail from "./DialogCheckinDetail";
import icon from "../../../constants/icon";
import moment from "moment";
export default function CheckinItem(props: any) {
    const { item, setRender, render } = props;
    const [openDialogCheckinDetail, setOpenDialogCheckinDetail] =
        useState(false);
    const handleOpenDialogDetail = () => {
        setOpenDialogCheckinDetail(true);
    };
    const handleCloseDialogDetail = () => {
        setOpenDialogCheckinDetail(false);
    };
    const [openDialogCheckinSuccess, setOpenDialogCheckinSuccess] =
        useState(false);
    const handleCloseDialogCheckinSuccess = () => {
        setOpenDialogCheckinSuccess(false);
    };
    const checkStatus = (item: any) => {
        switch (item.status) {
            case "KH Đã Đến":
                return (
                    <div
                        style={{ backgroundColor: "#FA91C8" }}
                        className="item-status"
                    >
                        {item.status}
                    </div>
                );
            case "Xác nhận đặt hẹn":
                return (
                    <>
                        <div
                            style={{ backgroundColor: "#9ACC51" }}
                            className="item-status"
                        >
                            {item.status}
                        </div>
                    </>
                );
            case "KH Mua":
                return (
                    <div
                        style={{ backgroundColor: "#69CAF7" }}
                        className="item-status"
                    >
                        {item.status}
                    </div>
                );
            case "Chưa xác nhận":
                return (
                    <div
                        style={{ backgroundColor: "var(--purple)" }}
                        className="item-status"
                    >
                        {item.status}
                    </div>
                );
            case "Không đến":
                return (
                    <div
                        style={{ backgroundColor: "#FAD733" }}
                        className="item-status"
                    >
                        {item.status}
                    </div>
                );
            case "Hủy":
                return (
                    <div
                        style={{ backgroundColor: "var(--red)" }}
                        className="item-status"
                    >
                        {item.status}
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <>
            <div
                onClick={() => {
                    handleOpenDialogDetail();
                }}
                className="checkin-item status-border"
            >
                <div className="checkin-item__top">
                    {item.status ? (
                        <> {checkStatus(item)}</>
                    ) : (
                        <>
                            <div
                                style={{
                                    backgroundColor: "var(--purple)",
                                }}
                                className="item-status"
                            >
                                <p>Chưa xác nhận</p>
                            </div>
                        </>
                    )}
                </div>
                <div className="checkin-img">
                    <img src={item?.organization?.image_url} alt="" />
                </div>
                <div className="checkin-content">
                    <p className="item-info color-purple">
                        {item?.organization?.name}
                    </p>
                    <div className="flex-row gap-row-4">
                        <img src={icon.clock} alt="" />
                        <p className="item-time">
                            {moment(item?.time_start).format(
                                "DD-MM-YYYY HH:mm"
                            )}
                        </p>
                    </div>
                    <div className="flex-row gap-row-4">
                        <div className="dots">
                            <p></p>
                        </div>
                        <p className="item-length">
                            {item?.services.length} Dịch vụ
                        </p>
                    </div>

                    <div className="item-arrow">
                        <img src={icon.arrowRightPurple} alt="" />
                    </div>
                </div>
            </div>

            <DialogCheckinDetail
                item={item}
                open={openDialogCheckinDetail}
                onClose={handleCloseDialogDetail}
                handleCloseDialogCheckinSuccess={
                    handleCloseDialogCheckinSuccess
                }
                openDialogCheckinSuccess={openDialogCheckinSuccess}
                setRender={setRender}
                render={render}
            />
        </>
    );
}
