import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import "./home.scss";
import HomeOption from "./Components/HomeOption";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearAllCart } from "../../redux/cartSlice";
import ButtonCart from "../../components/ButtonCart/ButtonCart";
import organizationAPI from "../../api/Organization";
import { getIdOrganization } from "../../redux/organization";
import DialogOTP from "./Components/DialogOTP";
import { clearAuth } from "../../redux/auth";
import { getSubDoMain } from "../../redux/subDoMain";
import img from "../../constants/img";
import { useHistory } from "react-router-dom";
import { onErrorImageOrg } from "utils/onErrorImgOrg";
export default function Home() {
    const history = useHistory();
    const user = useSelector((state: any) => state.authen.authInit);
    const org = useSelector((state: any) => state.organization?.organizationInit)
    const dispatch = useDispatch();
    const location: any = useLocation();
    const [counter, setCounter] = useState(1800);
    const [openDialogOTP, setOpenDialogOTP] = useState(true);
    const handleCloseOTP = () => {
        setOpenDialogOTP(false);
    };
    const SUBDOMAIN = useSelector(
        (state: any) => state.subDomain.subDoMainInit
    );
    const storageTimeEnd = "time-end";
    const timeEndSession: any = JSON.parse(
        `${sessionStorage.getItem(storageTimeEnd)}`
    );
    let subdomain = "";
    if (location.pathname === "/Home") {
        subdomain = SUBDOMAIN;
    } else {
        subdomain = location.pathname.split("/")[2];
    }
    let timeEndStr: any = null;
    if (timeEndSession) {
        timeEndStr = timeEndSession;
    } else {
        timeEndStr = location.search.slice(8);
    }
    const today: any = Date.now();
    const handleCheckTimeEnd = () => {
        if (!subdomain || subdomain === null) {
            history.push("/Scan");
            sessionStorage.removeItem(storageTimeEnd);
        } else {
            history.push("/Home");
            window.sessionStorage.setItem(
                storageTimeEnd,
                JSON.stringify(timeEndStr)
            );
            if (
                timeEndStr &&
                timeEndStr !== "" &&
                today < parseInt(timeEndStr) + 1800000
            ) {
                history.push("/Home");
                window.sessionStorage.setItem(
                    storageTimeEnd,
                    JSON.stringify(timeEndStr)
                );
            } else {
                history.push("/Scan");
                sessionStorage.removeItem(storageTimeEnd);
            }
        }
    };

    useEffect(() => {
        async function getOrg(subdomain: string) {
            try {
                const res = await organizationAPI.getOrg(subdomain);
                const action = res.data.context;
                dispatch(getSubDoMain(subdomain));
                dispatch(getIdOrganization(action));
                if (org.id !== action.id) {
                    dispatch(clearAllCart());
                    dispatch(clearAuth());
                }
            } catch (error) {
                console.log(error);
            }
        }
        handleCheckTimeEnd();
        getOrg(subdomain);
        if (user.token) {
            setOpenDialogOTP(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const timer: any =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        if (counter === 0) {
            history.push("/Scan");
            sessionStorage.removeItem(storageTimeEnd);
            dispatch(clearAllCart());
            dispatch(clearAuth());
        }
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);
    // console.log(user)
    return (
        <div className="home">
            <ButtonCart />
            {/* header */}
            <div className="home-head__wrap">
                <Container>
                    <div className="home-head">
                        <h1>My Checkin</h1>
                    </div>
                </Container>
            </div>
            {/* close header */}
            <Container>
                <div className="home-content">
                    {org?.image_url ? (
                        <div className="home-content__img">
                            <img
                                src={
                                    org?.image_url
                                        ? org?.image_url
                                        : img.logoMyspa
                                }
                                onError={(e) => {
                                    onErrorImageOrg(e, img.logoBeautyX);
                                }}
                                alt=""
                            />
                        </div>
                    ) : null}
                    <div className="home-content__title">
                        {org?.name ? (
                            <h2>
                                {org?.name} <br /> Kính chào quý khách!
                            </h2>
                        ) : (
                            <h2>Kính chào quý khách</h2>
                        )}
                    </div>
                    <HomeOption setOpenDialogOTP={setOpenDialogOTP} />
                </div>
            </Container>
            {
                !user?.token &&
                <DialogOTP
                    onClose={handleCloseOTP}
                    open={openDialogOTP}
                    handleCloseOTP={handleCloseOTP}
                />
            }
        </div>
    );
}
