import React from "react";
import icon from "../../constants/icon";
import "./scan.scss";
export default function Scan() {
    return (
        <div className="scan">
            <div className="scan-img">
                <img src={icon.Alert} alt="" />
            </div>
            <div className="scan-text">
                <p className="scan-text__notification">Notification !</p>
                <span>Vui lòng quét mã QR mới để tiếp tục</span>
            </div>
        </div>
    );
}
