import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import icon from "../../../constants/icon";
import formatPrice from "../../../utils/formatPrice";
import { IPromo } from "../../../interface/promo";
import { addPromo } from "../../../redux/promoSlice";
import DialogAlert from "../../../components/DialogAlert/DialogAlert";
import { onError } from "../../../utils/cusFunction";
import { IProductable } from "../../../interface/productable";
import { IPromoItem } from "../../../interface/promoItem";

export default function DialogEndow(props: any) {
    const {
        open,
        onClose,
        item,
        dateStart,
        dateEnd,
        productable,
        productableItem,
        dataPromo,
    } = props;
    const dispatch = useDispatch();
    const cartStore = useSelector((state: any) => state.carts.cartList);

    const [openDialogPromo, setOpenDialogPromo] = useState(false);
    const handleOpenDialogPromo = () => {
        setOpenDialogPromo(true);
    };
    const handleCloseDialogPromo = () => {
        setOpenDialogPromo(false);
    };
    const handleAddPromo = (dataPromo: IPromo) => {
        const action = addPromo(dataPromo);
        dispatch(action);
    };
    const gotoCart = (dataPromo: IPromo) => {
        // handleAddPromo(dataPromo);
        if (cartStore.length !== 0) {
            // history.push({
            //     pathname: "./Service",
            // });
            alert("Chức năng đang được xây dựng");
        } else {
            handleOpenDialogPromo();
        }
    };
    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <div className="dialog-detail__endow">
                    <div onClick={onClose} className="dialog-detail__close">
                        <img src={icon.increase} alt="" />
                    </div>
                    <div className="detail-img">
                        {productableItem.map(
                            (item: IProductable, index: number) => (
                                <img
                                    key={index}
                                    src={item?.image_url}
                                    onError={(e) => {
                                        onError(e);
                                    }}
                                    alt=""
                                />
                            )
                        )}
                    </div>
                    <div className="detail-content">
                        <div className="detail-name">
                            <p>{item.title}</p>
                        </div>
                        {productableItem.map(
                            (item: IProductable, index: number) => (
                                <p key={index} style={{ fontWeight: "bold" }}>
                                    Áp dụng cho:{" "}
                                    <span
                                        style={{
                                            color: "var(--green",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.service_name}
                                    </span>
                                </p>
                            )
                        )}
                        {item.items.map((item: IPromoItem, index: number) => (
                            <p className="detail-discout" key={index}>
                                {formatPrice(item.view_price)}đ
                            </p>
                        ))}
                        {productableItem.map(
                            (item: IProductable, index: number) => (
                                <p
                                    style={{
                                        textDecoration: "line-through",
                                    }}
                                    key={index}
                                >
                                    {formatPrice(item.price)}đ
                                </p>
                            )
                        )}

                        <div className="detail-date">
                            {productableItem.map(
                                (item: IProductable, index: number) => (
                                    <p key={index}>
                                        Thời gian: {item.duration} phút
                                    </p>
                                )
                            )}
                        </div>

                        <div className="detail-des">
                            {productable.map(
                                (item: IProductable, index: number) => (
                                    <p
                                        key={index}
                                        style={{ fontWeight: "bold" }}
                                    >
                                        Mô tả:{" "}
                                        <span style={{ color: "gray" }}>
                                            {item.description}
                                        </span>
                                    </p>
                                )
                            )}
                        </div>

                        <div className="detail-bot">
                            <div>
                                <div className="detail-date">
                                    <p>Từ: {dateStart}</p>
                                </div>
                                <div className="detail-date">
                                    <p>HSD: {dateEnd}</p>
                                </div>
                            </div>
                            <div
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    gotoCart(dataPromo);
                                }}
                                className="detail-btn"
                            >
                                <p>Sử dụng</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <DialogAlert
                open={openDialogPromo}
                onClose={handleCloseDialogPromo}
                title={`Vui lòng thêm sản phẩm, dịch vụ, combo vào giỏ hàng để sử dụng ưu đãi.`}
                url={`/Service`}
                titleClose={`Thêm ngay`}
                buttonClose
            />
        </>
    );
}
