import { createSlice } from "@reduxjs/toolkit";
const storageName = "promo-item";
const storage = JSON.parse(`${window.sessionStorage.getItem(storageName)}`);
const initialState = {
    promoItem: window.sessionStorage.getItem(storageName) ? storage : [],
};
const promo = createSlice({
    name: "promo",
    initialState,
    reducers: {
        addPromo: (state, action) => {
            state.promoItem = action.payload;
            window.sessionStorage.setItem(
                storageName,
                JSON.stringify(state.promoItem)
            );
        },
        clearAllPromo: (state) => {
            state.promoItem = [];
            window.sessionStorage.setItem(
                storageName,
                JSON.stringify(state.promoItem)
            );
        },
    },
});
const { reducer, actions } = promo;
export const { addPromo, clearAllPromo } = actions;
export default reducer;
