import React, { useState } from "react";
import DialogAddSuccess from "../../../components/Header/DialogAddSuccess";
import icon from "../../../constants/icon";
import { onError } from "../../../utils/cusFunction";
import formatPrice from "../../../utils/formatPrice";

export default function TabServiceItem(props: any) {
    const {  handleClickOpenDialogDetail, orgState, handleAddCart } =
        props;
    const [openDialogAddSuccess, setOpenDialogAddSuccess] = useState(false);
    const handleCloseDialogAddSuccess = () => {
        setOpenDialogAddSuccess(false);
    };
    const item = {
        ...props.item,
        price: parseInt(props.item?.price),
        special_price: parseInt(props.item?.special_price),
        special_price_momo: parseInt(props.item?.special_price_momo)
    }
    return (
        <>
            <div
                onClick={() => {
                    handleClickOpenDialogDetail(item);
                }}
                className="content-service__item"
            >
                <div className="item-top">
                    <div className="item-img">
                        <img
                            src={
                                item?.image_url
                                    ? item?.image_url
                                    : orgState.image_url
                            }
                            onError={(e) => {
                                onError(e);
                            }}
                            alt=""
                        />
                    </div>
                    <div className="item-name">
                        {item.service_name ? (
                            <p>{item.service_name}</p>
                        ) : (
                            <p>{item.description}</p>
                        )}
                    </div>
                </div>
                <div className="item-info">
                    <div className="item-time">
                        <p>{item.duration} Phút</p>
                    </div>

                    <div className="item-price">
                        <div className="price-wrap">
                            {item.special_price !== -1 && item.special_price ? (
                                <>
                                    <span
                                        style={{
                                            color: "var(--red",
                                        }}
                                    >
                                        {`${formatPrice(item.special_price)}`}đ
                                    </span>
                                    <span
                                        style={{
                                            textDecoration: "line-through",
                                            color: "gray",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {`${formatPrice(item.price)}`}đ
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span>{`${formatPrice(item.price)}`}đ</span>
                                </>
                            )}
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleAddCart(item);
                                setOpenDialogAddSuccess(true);
                            }}
                            className="item-price__img"
                        >
                            <img src={icon.increase} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <DialogAddSuccess
                open={openDialogAddSuccess}
                onClose={handleCloseDialogAddSuccess}
                item={item}
            />
        </>
    );
}
