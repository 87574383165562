import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogAddSuccess from "../../../components/Header/DialogAddSuccess";
import icon from "../../../constants/icon";
import { IProduct } from "../../../interface/product";
import { addCart } from "../../../redux/cartSlice";
import { onError } from "../../../utils/cusFunction";
import formatPrice from "../../../utils/formatPrice";
import DialogProductDetail from "./DialogProductDetail";

export default function TabServiceProductItem(props: any) {
    const item = {
        ...props.item,
        retail_price: parseInt(props.item?.retail_price),
        special_price: parseInt(props.item?.special_price),
    }
    const [itemProductDetail, setItemProductDetail] = useState<IProduct>();
    const orgState = useSelector(
        (state: any) => state.organization.organizationInit
    );
    const [openDialogDetail, setOpenDialogDetail] = useState(false);
    const dispatch = useDispatch();
    const [openDialogAddSuccess, setOpenDialogAddSuccess] = useState(false);
    const handleCloseDialogAddSuccess = () => {
        setOpenDialogAddSuccess(false);
    };
    const handleAddCart = (product: IProduct) => {
        const cartItemPro = {
            ...product,
            cart_id: parseInt(`1${product.id}`),
            quantity: 1,
            price_cart: product?.special_price
                ? product?.special_price
                : product?.retail_price,
            type: "PRODUCT",
        };
        const action = addCart(cartItemPro);
        dispatch(action);
    };

    const handleClickOpenDialogDetail = (item: IProduct) => {
        setOpenDialogDetail(true);
        setItemProductDetail(item);
    };

    const handleCloseDialogDetail = () => {
        setOpenDialogDetail(false);
    };
    return (
        <>
            <div
                className="content-product__item"
                onClick={() => {
                    handleClickOpenDialogDetail(item);
                }}
            >
                <div className="item-img">
                    <img
                        src={
                            item.image_url ? item.image_url : orgState.image_url
                        }
                        onError={(e) => {
                            onError(e);
                        }}
                        alt=""
                    />
                </div>
                <div className="item-content">
                    <div className="item-name">
                        <p> {item.product_name}</p>
                    </div>
                    <div className="item-bot">
                        <div className="flex-row-space-end">
                            <div className="flex-column">
                                {item.special_price !== -1 &&
                                item.special_price ? (
                                    <>
                                        <p className="item-bot__special">
                                            {`${formatPrice(
                                                parseInt(item.special_price)
                                            )}`}
                                            đ
                                        </p>
                                        <p
                                            style={{
                                                textDecoration: "line-through",
                                                color: "gray",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {`${formatPrice(
                                                parseInt(item.retail_price)
                                            )}`}
                                            đ
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p className="item-bot__price">
                                            {`${formatPrice(
                                                parseInt(item.retail_price)
                                            )}`}
                                            đ
                                        </p>
                                    </>
                                )}
                            </div>
                            <div
                                onClick={(e) => {
                                    handleAddCart(item);
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setOpenDialogAddSuccess(true);
                                }}
                                className="item-bot__btn"
                            >
                                <img src={icon.increase} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DialogProductDetail
                item={itemProductDetail}
                open={openDialogDetail}
                onClose={handleCloseDialogDetail}
            />
            <DialogAddSuccess
                open={openDialogAddSuccess}
                onClose={handleCloseDialogAddSuccess}
                item={item}
            />
        </>
    );
}
