import { Dialog } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import DialogAlert from "../../../components/DialogAlert/DialogAlert";
import icon from "../../../constants/icon";
import { onError } from "../../../utils/cusFunction";
import formatPrice from "../../../utils/formatPrice";

export default function DialogCheckinDetail(props: any) {
    const {
        open,
        onClose,
        item,
        openDialogCheckinSuccess,
        handleCloseDialogCheckinSuccess,
        setRender,
        render,
    } = props;
    const checkStatus = (item: any) => {
        switch (item.status) {
            case "KH Đã Đến":
                return (
                    <div
                        style={{ backgroundColor: "#FA91C8" }}
                        className="item-status"
                    >
                        {item.status}
                    </div>
                );
            case "Xác nhận đặt hẹn":
                return (
                    <>
                        <div
                            style={{ backgroundColor: "#9ACC51" }}
                            className="item-status"
                        >
                            {item.status}
                        </div>
                    </>
                );
            case "KH Mua":
                return (
                    <div
                        style={{ backgroundColor: "#69CAF7" }}
                        className="item-status"
                    >
                        {item.status}
                    </div>
                );
            case "Chưa xác nhận":
                return (
                    <div
                        style={{ backgroundColor: "var(--purple)" }}
                        className="item-status"
                    >
                        {item.status}
                    </div>
                );
            case "Không đến":
                return (
                    <div
                        style={{ backgroundColor: "#FAD733" }}
                        className="item-status"
                    >
                        {item.status}
                    </div>
                );
            case "Hủy":
                return (
                    <div
                        style={{ backgroundColor: "var(--red)" }}
                        className="item-status"
                    >
                        {item.status}
                    </div>
                );
            default:
                break;
        }
    };
    const org = useSelector(
        (state: any) => state.organization.organizationInit
    );
    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <div className="dialog-checkin">
                    <div onClick={onClose} className="dialog-detail__close">
                        <img src={icon.increase} alt="" />
                    </div>
                    <div className="flex-row-space dialog-checkin__title">
                        <p className="dialog-checkin__title">
                            Chi tiết đơn hàng
                        </p>
                        <p style={{ color: "black", fontSize: "14px" }}>
                            {moment(item?.time_start).format(
                                "DD-MM-YYYY HH:mm"
                            )}
                        </p>
                    </div>
                    <div className="dialog-checkin__org">
                        <div className="org-img">
                            <img src={item?.organization?.image_url} alt="" />
                        </div>
                        <div className="org-content">
                            <div className="item-name">
                                <p>{item?.organization?.name}</p>
                            </div>
                            <div className="item-address">
                                <p>{item?.organization?.full_address}</p>
                            </div>
                        </div>
                    </div>
                    <p className="dialog-checkin__title">
                        Tổng: ({item.services.length}) Dịch vụ
                    </p>
                    <div className="dialog-checkin__booking">
                        <div
                            style={{ border: "1px solid #80808042" }}
                            className="checkin-item"
                        >
                            <div className="checkin-item__top">
                                {item.status ? (
                                    <> {checkStatus(item)}</>
                                ) : (
                                    <>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    "var(--purple)",
                                            }}
                                            className="item-status"
                                        >
                                            <p>Chưa xác nhận</p>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="checkin-service__list">
                                {item?.services?.map((item: any, i: number) => (
                                    <div
                                        className="checkin-service__item"
                                        key={i}
                                    >
                                        <div className="service-item__img">
                                            <img
                                                src={
                                                    item?.image_url
                                                        ? item?.image_url
                                                        : org?.image_url
                                                }
                                                onError={(e) => {
                                                    onError(e);
                                                }}
                                                alt=""
                                            />
                                        </div>
                                        <div className="service-item__content">
                                            <div className="item-name">
                                                {item?.service_name}
                                            </div>
                                            <div className="flex-row gap-row-4">
                                                <img
                                                    style={{
                                                        width: "16px",
                                                        height: "16px",
                                                    }}
                                                    src={icon.tag}
                                                    alt=""
                                                />
                                                <div className="item-price">
                                                    {formatPrice(item?.price)}đ
                                                </div>
                                            </div>
                                            <div className="flex-row gap-row-4">
                                                <img src={icon.clock} alt="" />
                                                <div className="item-duration">
                                                    {item?.duration} phút
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <DialogAlert
                open={openDialogCheckinSuccess}
                onClose={handleCloseDialogCheckinSuccess}
                imageSuccess
                title={`Check-in thành công`}
                titleClose={`Đóng`}
                onCloseDialogBefore={onClose}
                setRender={setRender}
                render={render}
            />
        </>
    );
}
