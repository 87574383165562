import { Dialog } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import icon from "../../../constants/icon";
import { IServiceItem } from "../../../interface/serviceItem";
import { addCart } from "../../../redux/cartSlice";
import { onError } from "../../../utils/cusFunction";
import formatPrice from "../../../utils/formatPrice";

export default function DialogServiceDetail(props: any) {
    const { open, onClose } = props;
    const item = {
        ...props.item,
        price: parseInt(props.item?.price),
        special_price: parseInt(props.item?.special_price)
    }
    const dispatch = useDispatch();
    const [qua, setQua] = useState(1);
    const handleClose = () => {
        if (onClose) {
            onClose();
            setQua(1);
        }
    };
    const orgState = useSelector(
        (state: any) => state.organization.organizationInit
    );

    const handleAddCart = (service: IServiceItem) => {
        const cartItem = {
            ...service,
            cart_id: parseInt(`2${service.id}`),
            quantity: qua,
            price_cart: service.special_price
                ? service.special_price
                : service.price,
        };
        const action = addCart(cartItem);
        dispatch(action);
        handleClose();
    };

    const handleDecreaseCart = () => {
        if (qua === 1) {
            setQua(1);
        } else setQua(qua - 1);
    };

    const handleIncreaseCart = () => {
        setQua(qua + 1);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <div className="dialog-detail">
                <div onClick={handleClose} className="dialog-detail__close">
                    <img src={icon.increase} alt="" />
                </div>
                <div className="dialog-detail__img">
                    <img
                        src={
                            item?.image_url
                                ? item?.image_url
                                : orgState.image_url
                        }
                        onError={(e) => {
                            onError(e);
                        }}
                        alt=""
                    />
                </div>
                <div className="dialog-detail__content">
                    <div>
                        <div className="content-name">{item?.service_name}</div>
                        {item?.special_price !== -1 && item?.special_price ? (
                            <>
                                <div className="flex-column">
                                    <span
                                        style={{
                                            color: "var(--red",
                                        }}
                                    >
                                        <span style={{ color: "black" }}>
                                            Giá: {""}
                                        </span>
                                        <b>
                                            {`${formatPrice(
                                                parseInt(item?.special_price)
                                            )}`}
                                            đ
                                        </b>
                                    </span>
                                    <span
                                        style={{
                                            textDecoration: "line-through",
                                            color: "gray",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {`${formatPrice(
                                            parseInt(item?.price)
                                        )}`}
                                        đ
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <span>Giá: </span>
                                <span
                                    style={{
                                        color: "var(--purple)",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {`${formatPrice(parseInt(item?.price))}`}đ
                                </span>
                            </>
                        )}

                        {item?.duration ? (
                            <>
                                {" "}
                                <p className="content-time">
                                    Thời gian:{" "}
                                    <span>{item?.duration} Phút</span>
                                </p>
                            </>
                        ) : (
                            ""
                        )}
                        <p className="content-detail">
                            Mô tả:{" "}
                            {item?.description ? (
                                <>
                                    <span>{item.description}</span>
                                </>
                            ) : (
                                <span>Đang cập nhật</span>
                            )}
                        </p>
                    </div>
                    <div className="content-addcart">
                        <div className="item-amount">
                            <div
                                onClick={() => {
                                    handleDecreaseCart();
                                }}
                                className="amount-de"
                            >
                                <img src={icon.decrease} alt="" />
                            </div>
                            <span>{qua}</span>
                            <div
                                onClick={() => {
                                    handleIncreaseCart();
                                }}
                                className="amount-ce"
                            >
                                <img src={icon.increase} alt="" />
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                handleAddCart(item);
                            }}
                            className="btn-addcart"
                        >
                            <p>Thêm vào giỏ hàng</p>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
