import { Dialog } from "@mui/material";
import React from "react";
import TabServiceCart from "../../Service/Components/TabCart";
import icon from "../../../constants/icon";

export default function DialogCart(props: any) {
    const { open, onClose } = props;
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            sx={{ display: { xs: "block", lg: "none", xl: "none" } }}
        >
            <div className="dialog-cart">
                <TabServiceCart onClose={onClose} />
            </div>
        </Dialog>
    );
}
