import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import organizationAPI from "../../../api/Organization";
import { ICombo } from "../../../interface/combo";
import TabComboItem from "./TabComboItem";
interface IData {
    combos: ICombo[];
    page: number;
    totalItem: number;
}
export default function TabServiceCombo(props: any) {
    const { activeTab } = props;
    const orgID = useSelector(
        (state: any) => state.organization.organizationInit.id
    );
    const [data, setData] = useState<IData>({
        combos: [],
        page: 1,
        totalItem: 1,
    });
    const onViewMore = () => {
        if (data.combos.length < data.totalItem && data.combos.length >= 15) {
            setData({
                ...data,
                page: data.page + 1,
            });
        }
    };
    useEffect(() => {
        async function getComboByOrg() {
            try {
                const res = await organizationAPI.getComboByOrg(
                    orgID,
                    data.page
                );
                setData({
                    ...data,
                    combos: [...data.combos, ...res.data.context.data],
                    totalItem: res.data.context.total,
                });
            } catch (error) {
                console.log(error);
            }
        }
        getComboByOrg();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgID, data.page]);

    return (
        <div
            style={3 === activeTab ? { display: "block" } : { display: "none" }}
            className="content-combo"
        >
            {data.combos.length > 0 ? (
                <InfiniteScroll
                    dataLength={data.combos.length}
                    hasMore={true}
                    next={onViewMore}
                    height={"80vh"}
                    loader={<div></div>}
                >
                    <div className="combo-list">
                        {data.combos?.map((item: ICombo, index: number) => (
                            <div key={index} className="combo-item">
                                <TabComboItem item={item} />
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            ) : (
                <>
                    <p
                        style={{
                            textAlign: "center",
                            paddingTop: "16px",
                            fontWeight: "bold",
                        }}
                    >
                        Không có combo
                    </p>
                </>
            )}
        </div>
    );
}
