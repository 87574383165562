import React from "react";
import "./App.scss";
import RouterConfig from "./route/index";

function App() {
    return (
        <div>
            <RouterConfig />
        </div>
    );
}

export default App;
