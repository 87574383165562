import Dialog from "@mui/material/Dialog";
import React from "react";
import { useHistory } from "react-router-dom";
import icon from "../../constants/icon";
import img from "../../constants/img";
import "./dialogAlert.scss";
export default function DialogAlert(props: any) {
    const {
        open,
        onClose,
        title,
        titleClose,
        url,
        // onCloseDialogBefore,
        buttonClose,
        imageSuccess,
        imageError,
        reloadPage,
    } = props;
    const history = useHistory();
    const handleMoveUrl = () => {
        history.push(`${url}`);
    };
    // const handleCloseDialogBefore = () => {
    //     onCloseDialogBefore();
    // };
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="dialog-alert ">
                {/* nút đóng dialog */}
                {buttonClose ? (
                    <>
                        <div onClick={onClose} className="dialog-detail__close">
                            <img src={icon.increase} alt="" />
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {/* có props image Success */}
                {imageSuccess ? (
                    <>
                        <div className="dialog-alert__img">
                            <img src={img.confirm} alt="" />
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {/* có props image Error */}
                {imageError ? (
                    <>
                        <div className="dialog-alert__img">
                            <img src={img.error} alt="" />
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {/* có imageSuccess || imageError padding waring */}
                {imageSuccess || imageError ? (
                    <>
                        <p
                            style={{ marginTop: "32px" }}
                            className="title-alert"
                        >
                            Thông báo !
                        </p>
                    </>
                ) : (
                    <>
                        <p className="title-alert">Thông báo !</p>
                    </>
                )}

                <span className="title">{title}</span>

                <div
                    onClick={() => {
                        onClose();
                    }}
                    className="dialog-alert__btn"
                >
                    {/* có title button close */}
                    {titleClose ? (
                        <>
                            {/* có props reload Page */}
                            {reloadPage ? (
                                <>
                                    <p
                                        style={{
                                            backgroundColor: "var(--purple)",
                                        }}
                                        onClick={() => {
                                            window.location.reload();
                                        }}
                                        className="btn-success"
                                    >
                                        {titleClose}
                                    </p>
                                </>
                            ) : null}

                            {/* có url chuyển trang */}
                            {url ? (
                                <>
                                    {imageSuccess ? (
                                        <>
                                            <p
                                                style={{
                                                    backgroundColor:
                                                        "var(--green)",
                                                }}
                                                onClick={() => {
                                                    handleMoveUrl();
                                                }}
                                                className="btn-success"
                                            >
                                                {titleClose}
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p
                                                style={{
                                                    backgroundColor:
                                                        "var(--purple)",
                                                }}
                                                onClick={() => {
                                                    handleMoveUrl();
                                                }}
                                                className="btn-success"
                                            >
                                                {titleClose}
                                            </p>
                                        </>
                                    )}

                                    {imageError ? (
                                        <>
                                            <p
                                                style={{
                                                    backgroundColor:
                                                        "var(--purple)",
                                                }}
                                                onClick={() => {
                                                    handleMoveUrl();
                                                }}
                                                className="btn-success"
                                            >
                                                {titleClose}
                                            </p>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                // không có url
                                <>
                                    {imageSuccess ? (
                                        <>
                                            <p
                                                style={{
                                                    backgroundColor:
                                                        "var(--purple)",
                                                }}
                                                onClick={() => {
                                                    onClose();
                                                }}
                                                className="btn-success"
                                            >
                                                {titleClose}
                                            </p>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {imageError ? (
                                        <>
                                            <p
                                                style={{
                                                    backgroundColor:
                                                        "var(--purple)",
                                                }}
                                                onClick={() => {
                                                    onClose();
                                                }}
                                                className="btn-success"
                                            >
                                                {titleClose}
                                            </p>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        // không có title close
                        <>
                            {url ? (
                                <>
                                    <div
                                        onClick={() => {
                                            handleMoveUrl();
                                        }}
                                        className="btn-success"
                                    >
                                        <p>Đồng ý</p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {reloadPage ? (
                                        <div
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                            className="btn-success"
                                        >
                                            <p>Đồng ý</p>
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                onClose();
                                            }}
                                            className="btn-success"
                                        >
                                            <p>Đồng ý</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Dialog>
    );
}
