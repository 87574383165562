import { createSlice } from "@reduxjs/toolkit";
const storageName = "booking-cart";
const storage = JSON.parse(`${window.sessionStorage.getItem(storageName)}`);
const initialState = {
    cartList: window.sessionStorage.getItem(storageName) ? storage : [],
    cartQuantity: 0,
    cartAmount: 0,
};
const cart = createSlice({
    name: "carts",
    initialState,
    reducers: {
        addCart: (state, action) => {
            const iIndex = state.cartList.findIndex(
                (item: any) => item.cart_id === action.payload.cart_id
            );
            if (iIndex >= 0) {
                state.cartList[iIndex].quantity += action.payload.quantity;
            } else {
                const templeCart = {
                    ...action.payload,
                    quantity: action.payload.quantity,
                };
                state.cartList.push(templeCart);
            }
            window.sessionStorage.setItem(
                storageName,
                JSON.stringify(state.cartList)
            );
        },

        incItem: (state, action) => {
            const iIndex = state.cartList.findIndex(
                (item: any) => item.cart_id === action.payload.cart_id
            );
            if (state.cartList[iIndex].quantity >= 1) {
                state.cartList[iIndex].quantity += 1;
            }
            window.sessionStorage.setItem(
                storageName,
                JSON.stringify(state.cartList)
            );
        },

        decItem: (state, action) => {
            const iIndex = state.cartList.findIndex(
                (item: any) => item.cart_id === action.payload.cart_id
            );
            if (state.cartList[iIndex].quantity > 1) {
                state.cartList[iIndex].quantity -= 1;
            }
            window.sessionStorage.setItem(
                storageName,
                JSON.stringify(state.cartList)
            );
        },

        removeItem: (state, action) => {
            const nextItem = state.cartList.filter(
                (item: any) => item.cart_id !== action.payload.cart_id
            );
            state.cartList = nextItem;
            window.sessionStorage.setItem(
                storageName,
                JSON.stringify(nextItem)
            );
        },

        clearAllCart: (state) => {
            state.cartList = [];
            window.sessionStorage.setItem(
                storageName,
                JSON.stringify(state.cartList)
            );
        },

        getTotal: (state) => {
            let { total, quantity } = state.cartList.reduce(
                (cartTotal: any, cartItem: any) => {
                    const { quantity, special_price, price, retail_price } =
                        cartItem;
                    const itemTotal =
                        (special_price > 0
                            ? special_price
                            : price || retail_price) * quantity;
                    cartTotal.total += itemTotal;
                    cartTotal.quantity += quantity;
                    return cartTotal;
                },
                {
                    total: 0,
                    quantity: 0,
                }
            );
            state.cartAmount = total;
            state.cartQuantity = quantity;
        },
    },
});
const { reducer, actions } = cart;
export const { addCart, decItem, incItem, clearAllCart, removeItem, getTotal } =
    actions;
export default reducer;
