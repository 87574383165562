import React, { useEffect, useState } from "react";
import formatPrice from "../../../utils/formatPrice";
import icon from "../../../constants/icon";
import { onError } from "../../../utils/cusFunction";
import DialogServiceDetail from "./DialogServiceDetail";
import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../../../redux/cartSlice";
import organizationAPI from "../../../api/Organization";
import { ICateService } from "../../../interface/cateService";
import InfiniteScroll from "react-infinite-scroll-component";
import { IServiceItem } from "../../../interface/serviceItem";
import TabServiceItem from "./TabServiceItem";
import DialogAddSuccess from "../../../components/Header/DialogAddSuccess";
interface IData {
    services: IServiceItem[];
    page: number;
    totalItem: number;
}
export default function TabService(props: any) {
    const { activeTab } = props;
    const dispatch = useDispatch();
    const orgState = useSelector(
        (state: any) => state.organization.organizationInit
    );
    const [itemServiceDetail, setItemServiceDetail] = useState<IServiceItem>();
    const [openDialogDetail, setOpenDialogDetail] = useState(false);

    const [chooseCates, setChooseCates] = useState<number>(-1);
    const [data, setData] = useState<IData>({
        services: [],
        page: 1,
        totalItem: 1,
    });

    const orgID = useSelector(
        (state: any) => state.organization.organizationInit.id
    );
    const [cateService, setCateService] = useState<ICateService[]>([]);
    // get service cate by org
    useEffect(() => {
        async function getServiceCateByOrg() {
            try {
                const res = await organizationAPI.getServiceCateByOrg(orgID);
                setCateService(res?.data?.context.data);
            } catch (error) {
                console.log(error);
            }
        }
        getServiceCateByOrg();
    }, [orgID]);

    const onChooseCate = (cate_id: number) => {
        if (chooseCates !== cate_id) {
            setChooseCates(cate_id);
            setData({
                services: [],
                page: 1,
                totalItem: 1,
            });
        }
    };

    const handleClickOpenDialogDetail = (item: IServiceItem) => {
        setOpenDialogDetail(true);
        setItemServiceDetail(item);
    };

    const handleCloseDialogDetail = () => {
        setOpenDialogDetail(false);
    };

    const handleAddCart = (service: IServiceItem) => {
        const cartItem = {
            ...service,
            cart_id: parseInt(`2${service.id}`),
            quantity: 1,
            price_cart: service.special_price
                ? service.special_price
                : service.price,
            type: "SERVICE",
        };
        const action = addCart(cartItem);
        dispatch(action);
    };

    async function getServiceByCateId() {
        try {
            const res = await organizationAPI.getServicesByCateId(
                orgID,
                chooseCates === -1 ? "" : chooseCates,
                data.page
            );
            setData({
                ...data,
                services: [...data.services, ...res.data.context.data],
                totalItem: res.data.context.total,
            });
        } catch (error) {
            console.log(error);
        }
    }

    const onViewMore = () => {
        if (
            data.services.length < data.totalItem &&
            data.services.length >= 15
        ) {
            setData({
                ...data,
                page: data.page + 1,
            });
        }
    };

    async function getAllServicesByCateId() {
        try {
            const res = await organizationAPI.getAllServicesByCateId(
                orgID,
                data.page
            );
            setData({
                ...data,
                services: [...data.services, ...res.data.context.data],
                totalItem: res.data.context.total,
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (orgID) {
            getServiceByCateId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgID, data.page, chooseCates]);

    useEffect(() => {
        if (orgID) {
            if (chooseCates === -1) {
                getAllServicesByCateId();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.page, orgID, chooseCates]);

    return (
        <div
            style={1 === activeTab ? { display: "block" } : { display: "none" }}
            className="content-service"
        >
            <div className="content-cates">
                <div
                    style={
                        chooseCates === -1
                            ? {
                                  backgroundColor: "var(--purple)",
                                  color: "#fff",
                                  border: "1px solid var(--purple)",
                                  borderRadius: "16px",
                                  flexShrink: "0",
                              }
                            : {
                                  color: "var(--purple)",
                                  backgroundColor: "var(--bgWhite)",
                                  borderRadius: "16px",
                                  border: "1px solid var(--purple)",
                                  flexShrink: "0",
                              }
                    }
                    onClick={() => onChooseCate(-1)}
                    className="content-cate"
                >
                    <p>Tất cả</p>
                </div>

                {cateService.map((item: ICateService, index: number) => (
                    <div
                        style={
                            chooseCates === item.id
                                ? {
                                      backgroundColor: "var(--purple)",
                                      color: "#fff",
                                      border: "1px solid var(--purple)",
                                      borderRadius: "16px",
                                  }
                                : {
                                      color: "var(--purple)",
                                      backgroundColor: "var(--bgWhite)",
                                      borderRadius: "16px",
                                      border: "1px solid var(--purple)",
                                  }
                        }
                        onClick={() => onChooseCate(item.id)}
                        key={index}
                        className="content-cate"
                    >
                        <p>{item.name}</p>
                    </div>
                ))}
            </div>
            <div className="content-service__title">
                <p>Thông tin DV</p>
                <p>Thời gian</p>
                <p>Giá</p>
            </div>
            {data.services.length > 0 ? (
                <InfiniteScroll
                    dataLength={data.services.length}
                    hasMore={true}
                    next={onViewMore}
                    height={"80vh"}
                    loader={<div></div>}
                >
                    <div className="content-service__list">
                        {data.services?.map(
                            (item: IServiceItem, index: number) => (
                                <TabServiceItem
                                    item={item}
                                    key={index}
                                    handleClickOpenDialogDetail={
                                        handleClickOpenDialogDetail
                                    }
                                    orgState={orgState}
                                    handleAddCart={handleAddCart}
                                />
                            )
                        )}
                    </div>
                </InfiniteScroll>
            ) : (
                <></>
            )}
            <DialogServiceDetail
                open={openDialogDetail}
                item={itemServiceDetail}
                onClose={handleCloseDialogDetail}
            />
        </div>
    );
}
