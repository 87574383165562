import { Dialog } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import icon from "../../../constants/icon";
import { removeItem } from "../../../redux/cartSlice";

export default function DialogAlertDeleteItem(props: any) {
    const { open, onClose, item } = props;
    const dispatch = useDispatch();
    const handleDelete = (item: any) => {
        const action = removeItem(item);
        dispatch(action);
        onClose();
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="dialog-alert">
                <div onClick={onClose} className="dialog-detail__close">
                    <img src={icon.increase} alt="" />
                </div>
                <p className="title-alert">Thông báo !</p>
                <p className="title">
                    Bạn có muốn xóa{" "}
                    <span
                        style={{
                            textTransform: "capitalize",
                            color: "var(--purple",
                        }}
                    >
                        {" "}
                        {item?.product_name || item?.service_name || item?.name
                            ? item?.product_name ||
                              item?.service_name ||
                              item?.name
                            : item?.description}
                    </span>{" "}
                    ?
                </p>
                <div
                    onClick={() => {
                        handleDelete(item);
                    }}
                    className="btn-delete"
                >
                    <p>Đồng ý</p>
                </div>
            </div>
        </Dialog>
    );
}
