import React from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { RouteComponentProps } from "@reach/router";
import Home from "../features/Home";
import CheckIn from "../features/CheckIn";
import Endow from "../features/Endow";
import Evaluate from "../features/Evaluate";
import Service from "../features/Service";
import Scan from "../features/404";

const RouterPage = (
    props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;
export default function RouterConfigs() {
    const routes = [
        {
            component: <Home />,
            path: `/Home`,
        },
        {
            component: <CheckIn />,
            path: `/Check-in`,
        },
        {
            component: <Endow />,
            path: `/Endow`,
        },
        {
            component: <Evaluate />,
            path: `/Evaluate`,
        },
        {
            component: <Service />,
            path: `/Service`,
        },
        {
            component: <Scan />,
            path: `/Scan`,
        },
    ];
    return (
        <BrowserRouter>
            <Switch>
                <Redirect exact from="/" to="/Home" />
                {routes.map((item: any, index) => (
                    <RouterPage
                        key={index}
                        path={`${item.path}`}
                        pageComponent={item.component}
                    />
                ))}
            </Switch>
        </BrowserRouter>
    );
}
