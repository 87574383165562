import { createSlice } from "@reduxjs/toolkit";
const storageName = "auth";
const storage = JSON.parse(`${window.sessionStorage.getItem(storageName)}`);
const initialState = {
    authInit: window.sessionStorage.getItem(storageName) ? storage : {},
};
const authen = createSlice({
    name: "authens",
    initialState,
    reducers: {
        postAuth: (state, action) => {
            state.authInit = action.payload;
            window.sessionStorage.setItem(
                storageName,
                JSON.stringify(state.authInit)
            );
        },
        clearAuth: (state) => {
            state.authInit = {};
            window.sessionStorage.removeItem(storageName);
        },
    },
});
const { reducer, actions } = authen;
export const { postAuth, clearAuth } = actions;
export default reducer;
