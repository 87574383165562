/* eslint-disable eqeqeq */
import React, { useMemo, useState } from "react";
import TabServiceCartItem from "./TabCartItem";
import { useDispatch, useSelector } from "react-redux";
import { clearAllCart, getTotal } from "../../../redux/cartSlice";
import formatPrice from "../../../utils/formatPrice";
import moment from "moment";
import { formatRequestBooking } from "../../../utils/useFormatCart";
import appointmentAPI from "../../../api/Appointment";
import img from "../../../constants/img";
import DialogAlert from "../../../components/DialogAlert/DialogAlert";
import { IService } from "../../../interface/service";
import orderApi from "../../../api/Order";
import icon from "../../../constants/icon";

export default function TabServiceCart(props: any) {
    const { onClose } = props;
    const [load, setLoad] = useState(false)
    // const history = useHistory();
    const dispatch = useDispatch();
    const cartStore = useSelector((state: any) => state.carts);
    // const checkinStore = useSelector((state: any) => state.checkin);
    const promoStore = useSelector((state: any) => state.promo.promoItem);
    const orgID = useSelector(
        (state: any) => state.organization.organizationInit.id
    );
    const userToken = useSelector((state: any) => state.authen.authInit.token);
    // const discount =
    //     promoStore.length !== 0
    //         ? promoStore.promoItem.map((item: any) => item.discount)
    //         : promoStore;
    // const discountItem = discount.map((item: any) => item.coupon_code);
    // const priceSpecial = promoStore?.promoItem?.map(
    //     (item: any) => item.view_price
    // );
    // const productable = promoStore.productableItem.map((item: any) => item);
    const cartsList = cartStore.cartList;
    const { service_ids, product_ids, treatment_ids } =
        formatRequestBooking(cartsList);
    // console.log("service_ids", service_ids);
    const totalCount = cartStore.cartAmount;
    const [openDialogAlertPromo, setOpenDialogAlertPromo] = useState(false);
    const [openDialogAlertSubmitSeemore, setOpenDialogAlertSubmitSemore] =
        useState(false);
    const [openDialogAlertSubmit, setOpenDialogAlertSubmit] = useState(false);
    const [openDialogAlertSubmitErrLogin, setOpenDialogAlertSubmitErrLogin] =
        useState(false);
    const [openDialogAlertSubmitErr, setOpenDialogAlertSubmitErr] =
        useState(false);
    const subDomain = useSelector(
        (state: any) => state.subDomain.subDoMainInit
    );
    const today = new Date();
    let start_date = moment(today.setSeconds(today.getSeconds() + 300)).format(
        "YYYY-MM-DD HH:mm:ss"
    );
    // const handleOpenDialogAlertPromo = () => {
    //     setOpenDialogAlertPromo(true);
    // };
    const handleCloseDialogAlertPromo = () => {
        setOpenDialogAlertPromo(false);
    };
    const handleCloseDialogSubmitSeemore = () => {
        setOpenDialogAlertSubmitSemore(false);
        const action = clearAllCart();
        dispatch(action);
    };
    const handleCloseDialogSubmit = () => {
        setOpenDialogAlertSubmit(false);
        const action = clearAllCart();
        dispatch(action);
    };
    const handleCloseDialogAlertPromoErr = () => {
        setOpenDialogAlertSubmitErr(false);
    };
    const handleCloseDialogAlertSubmitErrLogin = () => {
        setOpenDialogAlertSubmitErrLogin(false);
    };

    const [idOrder, setIdOrder] = useState<any>();
    async function postSubmit(params: any, userToken: string) {
        setLoad(true)
        let values = {
            services: service_ids.map((item: any) => item.id),
            time_start: start_date,
            org_id: orgID,
            order_id: idOrder,
        };
        try {
            const res = await orderApi.postOder(params, userToken);
            setIdOrder(res.data.context.id);
            values = {
                ...values,
                order_id: res.data.context.id,
            };
            if (service_ids.length > 0) {
                postAppointMent(values, userToken);
                setOpenDialogAlertSubmitSemore(true);
            } else if (
                service_ids.length > 0 &&
                (product_ids.length > 0 || treatment_ids.length > 0)
            ) {
                setOpenDialogAlertSubmitSemore(true);
            } else {
                setOpenDialogAlertSubmit(true);
            }
            setLoad(false)
        } catch (error) {
            if (error) {
                setOpenDialogAlertSubmitErr(true);
            }
            setLoad(false)
            console.log(error);
        }
    }

    async function postAppointMent(values: any, userToken: string) {
        try {
            await appointmentAPI.postAppointMent(values, userToken);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = () => {
        if (orgID && userToken) {
            const params = {
                products: product_ids,
                services: service_ids,
                prepay_cards: [],
                combos: treatment_ids,
                payment_method_id: 9,
                coupon_code: [],
                org_id: orgID,
                description: "",
            };

            postSubmit(params, userToken);
            // console.log("params", params);
        } else {
            setOpenDialogAlertSubmitErrLogin(true);
        }
    };

    // const calcSerProPromotion = (promoStore: any, carts: any) => {
    //     let total = 0;
    //     for (const item of carts) {
    //         const data = promoStore.items.some((pro: any) => pro.id == item.id);
    //         if (data) {
    //             if (promoStore?.discount_unit == "%") {
    //                 total +=
    //                     (promoStore?.discount_value / 100) *
    //                     (item.special_price > 0
    //                         ? item.special_price
    //                         : item.price) *
    //                     item.quantity;
    //             } else total += promoStore?.discount_value * item.quantity;
    //         }
    //     }
    //     return total;
    // };

    // const calcDiscount = useMemo((): number => {
    //     let total = 0;
    //     if (promoStore) {
    //         if (promoStore.discount_type == "1")
    //             if (promoStore?.discount_unit == "%")
    //                 total += (promoStore?.discount_value / 100) * totalCount;
    //             else total += parseInt(promoStore?.discount_value);

    //         if (promoStore.discount_type == "2")
    //             total = calcSerProPromotion(promoStore, cartsList);
    //     }
    //     return total;
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [promoStore, cartsList]);

    // const chooseCoupon = () => {
    //     if (cartsList.length === 0) {
    //         handleOpenDialogAlertPromo();
    //     } else {
    //         history.push("./Endow");
    //     }
    // };

    // const handleRemovePromo = () => {
    //     const action = clearAllPromo();
    //     dispatch(action);
    // };

    // useMemo(() => {
    //     if (cartsList.length === 0) {
    //         const action = clearAllPromo();
    //         dispatch(action);
    //     }
    // }, [cartsList.length, dispatch]);

    useMemo(() => {
        dispatch(getTotal());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, cartStore]);

    // let totalCart = totalCount - calcDiscount;
    let totalCart = totalCount;
    return (
        <div className="service-cart">
            <div className="service-cart__title">
                <div onClick={onClose} className="service-cart_btnclose">
                    <img src={icon.arrowLeft} alt="" />
                </div>
                <p>Giỏ hàng</p>
            </div>
            <div className="service-cart__text">
                <p>Sản phẩm/dịch vụ</p>
                <p>Thành tiền</p>
            </div>
            <div className="service-cart__list">
                {cartStore?.cartList.length !== 0 ? (
                    <>
                        {cartStore?.cartList.map(
                            (item: IService, index: number) => (
                                <div key={index} className="service-cart__item">
                                    <TabServiceCartItem
                                        promoStore={promoStore}
                                        // calcDiscount={calcDiscount}
                                        itemCart={item}
                                    />
                                </div>
                            )
                        )}
                    </>
                ) : (
                    <>
                        <div className="cart-list__empty">
                            <div className="list-empty__img">
                                <img src={img.emptyCart} alt="" />
                            </div>
                            <p className="list-empty__title">Giỏ hàng trống</p>
                        </div>
                    </>
                )}
            </div>
            <div className="service-cart__payment">
                {/* <div className="payment-endow">
                    {promoStore.length !== 0 ? (
                        <>
                            <p>Tạm tính:</p>
                            <p
                                style={{
                                    fontSize: "14px",
                                }}
                            >
                                {`${formatPrice(totalCount)}`}đ
                            </p>
                        </>
                    ) : (
                        <></>
                    )}
                </div> */}
                {/* {promoStore.length !== 0 ? (
                    <>
                        <div className="payment-endow">
                            <p>
                                Giảm giá:{" "}
                                <span
                                    style={{
                                        color: "var(--green)",
                                    }}
                                >
                                    {`${formatPrice(priceSpecial)}`}đ
                                </span>{" "}
                                {promoStore.discount_type == 1 ? (
                                    <>
                                        <span
                                            style={{
                                                color: "var(--green)",
                                            }}
                                        >
                                            tổng đơn
                                        </span>
                                    </>
                                ) : (
                                    <span
                                        style={{
                                            color: "var(--green)",
                                        }}
                                    >
                                        từng SP
                                    </span>
                                )}
                            </p>
                            <p
                                style={{
                                    color: "var(--red)",
                                    fontSize: "14px",
                                }}
                            >
                                {`- ${formatPrice(calcDiscount)}`}đ
                            </p>
                        </div>
                    </>
                ) : (
                    <></>
                )} */}

                <div className="payment-endow">
                    <p>Tổng tiền:</p>
                    {totalCount === 0 ? (
                        <p>0đ</p>
                    ) : (
                        <>
                            <p>
                                {`${formatPrice(
                                    totalCart < 0 ? 0 : totalCart
                                )}`}
                                đ
                            </p>
                        </>
                    )}
                </div>

                {/* {totalCart > 0 ? (
                    <>
                        {calcDiscount > 0 || promoStore.length === 0 ? (
                            ""
                        ) : (
                            <>
                                <p
                                    style={{
                                        width: "100%",
                                        textAlign: "start",
                                        color: "var(--red)",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Mặt hàng không áp dụng mã ưu đãi này
                                </p>
                            </>
                        )}
                    </>
                ) : (
                    ""
                )} */}

                {/* nếu tổng tiền tạp tính nhỏ hơn tổng tổng tiền item khi dùng mã giảm giá */}
                {/* {totalCount < calcDiscount ? (
                    <p
                        style={{
                            width: "100%",
                            textAlign: "start",
                            color: "var(--red)",
                            fontWeight: "bold",
                        }}
                    >
                        Mặt hàng không áp dụng mã ưu đãi này
                    </p>
                ) : (
                    <></>
                )} */}

                <div className="payment-all">
                    <div
                        onClick={() => {
                            // chooseCoupon();
                        }}
                        className="payment-all__choose"
                    >
                        {/* btn chon uu dai */}
                        {/* {promoStore.length !== 0 ? (
                            <>
                                <p
                                    className="text-limit-1"
                                    style={{
                                        maxWidth: "340px",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    {discountItem}
                                </p>
                                <div
                                    style={{ transform: "rotate(45deg)" }}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleRemovePromo();
                                    }}
                                    className="payment-all__btn"
                                >
                                    <img src={icon.increase} alt="" />
                                </div>
                            </>
                        ) : (
                            <>
                                <p>Chọn ưu đãi</p>
                                <div className="payment-all__btn">
                                    <img src={icon.tag} alt="" />
                                </div>
                            </>
                        )} */}
                    </div>

                    {/* calcDiscount : tổng tiền item khi dùng mã giảm giá */}
                    {/* totalCart : tổng tiền tất cả giỏ hàng sau khi dùng mã giảm giá */}
                    {/* totalCount : tạm tính */}

                    {/* {totalCart < 0 ||
                    cartsList.length === 0 ||
                    (calcDiscount === 0 &&
                        totalCart === totalCount &&
                        promoStore.length !== 0) ? (
                        <>
                            <p
                                style={{
                                    pointerEvents: "none",
                                    opacity: "0.5",
                                    cursor: "unset",
                                }}
                            >
                                Gửi yêu cầu
                            </p>
                        </>
                    ) : (
                        <>
                            <p
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Gửi yêu cầu
                            </p>
                        </>
                    )} */}

                    {(cartsList.length === 0 || load) ? (
                        <>
                            <p
                                style={{
                                    pointerEvents: "none",
                                    opacity: "0.5",
                                    cursor: "unset",
                                }}
                            >
                                {load ? 'Đang gửi...' : 'Gửi yêu cầu'}
                            </p>
                        </>
                    ) : (
                        <p
                            onClick={handleSubmit}
                        >
                            Gửi yêu cầu
                        </p>
                    )}
                </div>
            </div>

            <DialogAlert
                title={`Gửi yêu cầu thành công`}
                open={openDialogAlertSubmitSeemore}
                onClose={handleCloseDialogSubmitSeemore}
                titleClose={`Xem lịch hẹn ngay`}
                imageSuccess
                url={`/Check-in`}
            />
            <DialogAlert
                title={`Gửi yêu cầu thành công`}
                open={openDialogAlertSubmit}
                onClose={handleCloseDialogSubmit}
                imageSuccess
                titleClose={`Đóng`}
            />
            <DialogAlert
                title={`Gửi yêu cầu thất bại, vui lòng thử lại ...`}
                open={openDialogAlertSubmitErr}
                onClose={handleCloseDialogAlertPromoErr}
                titleClose={`Đồng í`}
                imageError
            />
            <DialogAlert
                title={` Vui lòng thêm sản phẩm, dịch vụ, combo vào giỏ hàng để sử
                    dụng ưu đãi.`}
                open={openDialogAlertPromo}
                onClose={handleCloseDialogAlertPromo}
            />
            <DialogAlert
                title={` Vui lòng nhập số điện thoại và check-in để sử dụng chức năng này.`}
                open={openDialogAlertSubmitErrLogin}
                onClose={handleCloseDialogAlertSubmitErrLogin}
                titleClose={`Đồng ý`}
                url={`/Home/${subDomain}`}
            />
        </div>
    );
}
