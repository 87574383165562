import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import DialogCodeOTP from "./DialogCodeOTP";
import img from "../../../constants/img";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { authentication } from "../../../firebase";
import DialogAlert from "../../../components/DialogAlert/DialogAlert";

declare global {
    interface Window {
        recaptchaVerifier: any;
        confirmationResult: any;
        recaptchaWidgetId: any;
    }
}
// window.recaptchaVerifier = window.recaptchaVerifier || {};
window.confirmationResult = window.confirmationResult || {};

export default function DialogOTP(props: any) {
    const { onClose, open, handleCloseOTP } = props;
    const [openDialogCodeOTP, setOpenDialogCodeOTP] = useState(false);
    const [openDialogExceededCode, setOpenDialogExceededCode] = useState(false);
    const [openDialogReloadPage, setOpenDialogReloadPage] = useState(false);
    const [valuePhone, setValuePhone] = useState("");
    const [verificationId, setVerificationId] = useState("");
    const [loading, setLoading] = useState(false);

    const handleCloseOpenDialogCodeOTP = () => {
        setOpenDialogCodeOTP(false);
    };
    const handleCloseOpenDialogExceededCode = () => {
        setOpenDialogExceededCode(false);
    };
    const handleCloseReloadPage = () => {
        setOpenDialogReloadPage(false);
    };
    const generateRecaptcha = () => {
        try {
            console.log(window.recaptchaVerifier);
            if (!window.recaptchaVerifier) {
                window.recaptchaVerifier = new RecaptchaVerifier(
                    "recaptcha-container",
                    {
                        size: "invisible",
                        callback: (values: any) => {
                            // handleSubmit(values);
                        },
                    },
                    authentication
                );
            } else {
                window.recaptchaVerifier.render();
            }
        } catch (err: any) {
            console.log(err);
        }
    };

    const handleSubmit = (values: any, isRecaptcha: Boolean) => {
        setLoading(true);
        if (values) {
            setValuePhone(values);
            generateRecaptcha();
            let phoneNumberVN = "+84" + values.slice(1);
            signInWithPhoneNumber(
                authentication,
                phoneNumberVN,
                window.recaptchaVerifier
            )
                .then((confirmationResult: any) => {
                    setVerificationId(confirmationResult.verificationId);
                    window.confirmationResult = confirmationResult;
                    setOpenDialogCodeOTP(true);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    let errorCode = error.code;
                    let messCode = error.message;
                    if (
                        errorCode === "auth/quota-exceeded" ||
                        errorCode === "auth/too-many-requests"
                    ) {
                        setOpenDialogExceededCode(true);
                    } else if (
                        messCode ===
                        "reCAPTCHA has already been rendered in this element"
                    ) {
                        setOpenDialogReloadPage(true);
                    }
                });
        }
    };

    const formik = useFormik({
        initialValues: {
            phone: "",
        },
        validationSchema: Yup.object({
            phone: Yup.string()
                .trim()
                .required("Số điện thoại không được để trống")
                .min(10, "Số điện thoại không được nhỏ hơn 9 chữ số")
                .max(11, "Số điện thoại không được lớn hơn 11 chữ số")
                .matches(
                    /(03|05|07|08|09|01|84|0[2|6|8|9])+([0-9]{8,9})\b/,
                    "Số điện thoại không đúng định dạng"
                ),
        }),
        // SUBMIT
        onSubmit: (values: any) => {
            handleSubmit(values.phone, false);
        },
    });

    const dataCheckin = {
        telephone: valuePhone,
        verification_id: verificationId,
    };

    useEffect(() => {
        const recaptchaVerifier = () => window.recaptchaVerifier;
        recaptchaVerifier();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.recaptchaVerifier]);
    return (
        <Dialog fullScreen onClose={onClose} open={open}>
            <div className="dialog-OTP">
                <div className="w-50 dialog-OTP__wrap">
                    <div className="dialog-OTP__img">
                        <img src={img.login} alt="" />
                    </div>
                    <div className="dialog-OTP__content">
                        <p className="item-title">OTP Verification</p>
                        <p className="item-desc">
                            Vui lòng nhập số điện thoại để xác thực
                        </p>
                        <form
                            onSubmit={formik.handleSubmit}
                            autoComplete="off"
                            action=""
                        >
                            <div className="number-input">
                                <input
                                    maxLength={11}
                                    minLength={10}
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    name="phone"
                                    type="text"
                                    placeholder="Nhập số điện thoại"
                                />
                            </div>
                            {formik.errors.phone && formik.touched.phone && (
                                <p
                                    style={{
                                        marginTop: "16px",
                                    }}
                                    className="text-error"
                                >
                                    {formik.errors.phone}
                                </p>
                            )}

                            <button
                                style={
                                    loading === true
                                        ? {
                                              opacity: "0.5",
                                              pointerEvents: "none",
                                          }
                                        : { opacity: "1" }
                                }
                                type="submit"
                                className="btn-otp"
                            >
                                {loading === true ? (
                                    <div className="lds-dual-ring"></div>
                                ) : (
                                    `Lấy mã xác thực`
                                )}
                            </button>
                            <div id="recaptcha-container"></div>
                        </form>
                    </div>
                </div>
            </div>
            <DialogCodeOTP
                onClose={handleCloseOpenDialogCodeOTP}
                open={openDialogCodeOTP}
                valuePhone={valuePhone}
                handleCloseOTP={handleCloseOTP}
                dataCheckin={dataCheckin}
                handleRecaptcha={handleSubmit}
            />
            <DialogAlert
                title={`Số điện thoại đã đạt giới hạn cho phép gửi mã xác thực (OTP) trong ngày`}
                open={openDialogExceededCode}
                onClose={handleCloseOpenDialogExceededCode}
            />
            <DialogAlert
                title={`Vui lòng bấm nút tải lại trang để tiếp tục ...`}
                open={openDialogReloadPage}
                onClose={handleCloseReloadPage}
                reloadPage
                titleClose={`Tải lại trang`}
            />
        </Dialog>
    );
}
