import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import firebase from 'firebase/app'
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    // live
    apiKey: "AIzaSyDpR6tnzXJaKhp_uHsHEpHVn9o9tMQeASU",
    authDomain: "myspa-api-2197e.firebaseapp.com",
    projectId: "myspa-api-2197e",
    storageBucket: "myspa-api-2197e.appspot.com",
    messagingSenderId: "91288679971",
    appId: "1:91288679971:web:7b301c6698c90469396396",
    measurementId: "G-SVW84J67ZJ",
    // test
    // apiKey: "AIzaSyCKr8P1F42G7Y_x7LBkbIO96xVVSVxCyfg",
    // authDomain: "app-checkin-test.firebaseapp.com",
    // projectId: "app-checkin-test",
    // storageBucket: "app-checkin-test.appspot.com",
    // messagingSenderId: "395382988799",
    // appId: "1:395382988799:web:dbdf5c7de7957e5c0a91ca",
    // measurementId: "G-5K4B1FFSVQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const authentication = getAuth(app);
