import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import organizationAPI from "../../../api/Organization";
import { ICateProduct } from "../../../interface/cateProduct";
import { IProduct } from "../../../interface/product";
import TabServiceProductItem from "./TabProductItem";
import InfiniteScroll from "react-infinite-scroll-component";

interface IData {
    products: IProduct[];
    page: number;
    totalItem: number;
}
export default function TabServiceProduct(props: any) {
    const { activeTab } = props;
    const orgID = useSelector(
        (state: any) => state.organization.organizationInit.id
    );
    const [cates, setCates] = useState<ICateProduct[]>([]);
    const [chooseCates, setChooseCates] = useState<number>(-1);
    const onChooseCate = (cate_id: number) => {
        if (chooseCates !== cate_id) {
            setChooseCates(cate_id);
            setData({
                products: [],
                page: 1,
                totalItem: 1,
            });
        }
    };
    const [data, setData] = useState<IData>({
        products: [],
        page: 1,
        totalItem: 1,
    });
    const onViewMore = () => {
        if (
            data.products.length < data.totalItem &&
            data.products.length >= 15
        ) {
            setData({
                ...data,
                page: data.page + 1,
            });
        }
    };

    useEffect(() => {
        async function getProductCateByOrg() {
            try {
                const res = await organizationAPI.getProductByCateOrg(orgID);
                setCates(res?.data?.context.data);
            } catch (error) {
                console.log(error);
            }
        }
        getProductCateByOrg();
    }, [orgID]);

    useEffect(() => {
        async function getProductByCateId() {
            try {
                const res = await organizationAPI.getProductByCateId(
                    orgID,
                    chooseCates === -1 ? "" : chooseCates,
                    data.page
                );
                setData({
                    ...data,
                    products: [...data.products, ...res.data.context.data],
                    totalItem: res.data.context.total,
                });
            } catch (error) {
                console.log(error);
            }
        }
        if (orgID) {
            getProductByCateId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chooseCates, orgID, data.page]);

    useEffect(() => {
        async function getAllProductByCateId() {
            try {
                const res = await organizationAPI.getAllProductByCateId(
                    orgID,
                    data.page
                );
                setData({
                    ...data,
                    products: [...data.products, ...res.data.context.data],
                    totalItem: res.data.context.total,
                });
            } catch (error) {
                console.log(error);
            }
        }
        if (orgID) {
            if (chooseCates === -1) {
                getAllProductByCateId();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chooseCates, orgID, data.page]);

    return (
        <div
            style={2 === activeTab ? { display: "block" } : { display: "none" }}
            className="content-product"
        >
            {cates.length > 0 ? (
                <>
                    <div className="content-cates">
                        <div
                            style={
                                chooseCates === -1
                                    ? {
                                          backgroundColor: "var(--purple)",
                                          color: "#fff",
                                          border: "1px solid var(--purple)",
                                          borderRadius: "16px",
                                          flexShrink: "0",
                                      }
                                    : {
                                          color: "var(--purple)",
                                          backgroundColor: "var(--bgWhite)",
                                          borderRadius: "16px",
                                          border: "1px solid var(--purple)",
                                          flexShrink: "0",
                                      }
                            }
                            onClick={() => onChooseCate(-1)}
                            className="content-cate"
                        >
                            <p>Tất cả</p>
                        </div>

                        {cates.map((item: ICateProduct, index: number) => (
                            <div
                                style={
                                    chooseCates === item.id
                                        ? {
                                              backgroundColor: "var(--purple)",
                                              color: "#fff",
                                              border: "1px solid var(--purple)",
                                              borderRadius: "16px",
                                          }
                                        : {
                                              color: "var(--purple)",
                                              backgroundColor: "var(--bgWhite)",
                                              borderRadius: "16px",
                                              border: "1px solid var(--purple)",
                                          }
                                }
                                onClick={() => onChooseCate(item.id)}
                                key={index}
                                className="content-cate"
                            >
                                <p>{item.name}</p>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}

            {data.products.length > 0 ? (
                <InfiniteScroll
                    dataLength={data.products.length}
                    hasMore={true}
                    next={onViewMore}
                    height={"80vh"}
                    loader={<div></div>}
                >
                    <div className="content-product__list">
                        {data.products.map((item, index: number) => (
                            <TabServiceProductItem key={index} item={item} />
                        ))}
                    </div>
                </InfiniteScroll>
            ) : (
                <></>
            )}
        </div>
    );
}
