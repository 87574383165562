import logoBeautyX from "../assets/image/logoBeautyX.png";
import confirm from "../assets/image/confirm.png";
import error from "../assets/image/error.png";
import signin from "../assets/image/signin.png";
import login from "../assets/image/login.svg";
import codeSnippet from "../assets/image/code_snippets.svg";
import avatar from "../assets/image/avatar.png";
import emptyCart from "../assets/image/empty-cart.png";
import logoMyspa from "../assets/image/logo-myspa.png";
const img = {
    codeSnippet,
    error,
    logoMyspa,
    emptyCart,
    avatar,
    login,
    signin,
    confirm,
    logoBeautyX,
};
export default img;
