import axiosClient from "./axiosClient";
class GetPromo {
    getPromo = (id_org: number) => {
        const params = {
            "filter[organization_id]": id_org,
            "filter[platform]": "MOMO",
        };
        const url = `/discounts`;
        return axiosClient.get(url, { params });
    };
}
const promoAPI = new GetPromo();
export default promoAPI;
