import { Dialog } from "@mui/material";
import img from "../../../constants/img";

export default function DialogConfirm(props: any) {
    const { openConfirm, onClose, title, content, imageSuccess, imageError } =
        props;

    return (
        <div className="dialog-confirm">
            <Dialog open={openConfirm} onClose={onClose}>
                <div className="dialog-confirm__wrapper">
                    {imageSuccess ? (
                        <>
                            <div className="dialog-confirm__img">
                                <img src={img.confirm} alt="" />
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {imageError ? (
                        <>
                            <div className="dialog-confirm__img">
                                <img src={img.error} alt="" />
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    <div className="dialog-confirm__desc">
                        {title ? (
                            <>
                                <p>{title}</p>
                            </>
                        ) : (
                            <></>
                        )}
                        <p>{content}</p>
                    </div>
                    <div
                        onClick={() => {
                            onClose();
                        }}
                        className="dialog-confirm__btn"
                    >
                        {imageSuccess ? (
                            <>
                                <p style={{ backgroundColor: "var(--green)" }}>
                                    Đóng
                                </p>
                            </>
                        ) : (
                            <></>
                        )}
                        {imageError ? (
                            <>
                                <p style={{ backgroundColor: "var(--red)" }}>
                                    Đóng
                                </p>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
