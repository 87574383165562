import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authenApi from "../../../api/Auth";
import DialogAlert from "../../../components/DialogAlert/DialogAlert";
import icon from "../../../constants/icon";
import img from "../../../constants/img";
import { postAuth } from "../../../redux/auth";

export default function DialogCodeOTP(props: any) {
    const {
        onClose,
        open,
        valuePhone,
        handleCloseOTP,
        handleRecaptcha,
        dataCheckin,
    } = props;
    const dispatch = useDispatch();
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const SUBDOMAIN = useSelector(
        (state: any) => state.subDomain.subDoMainInit
    );
    const [loading, setLoading] = useState(false);
    const valueOTP = otp.join("");
    const [counter, setCounter] = useState(59);

    const [openDialogErrorExpiredCode, setOpenDialogErrorExpiredCode] =
        useState(false);
    const [openDialogErrorIvalidCode, setOpenDialogErrorIvalidCode] =
        useState(false);
    const [openDialogEmptyNumber, setOpenDialogEmptyNumber] = useState(false);
    const handleCloseDialogErrorIvalidCode = () => {
        setOpenDialogErrorIvalidCode(false);
    };
    const handleCloseDialogErrorExpiredCode = () => {
        setOpenDialogErrorExpiredCode(false);
    };
    const handleCloseDialogEmptyNumber = () => {
        setOpenDialogEmptyNumber(false);
    };

    const handleChange = (e: any, index: number) => {
        if (isNaN(e.target.value)) return false;
        setOtp([
            ...otp.map((item, idx) => (idx === index ? e.target.value : item)),
        ]);
        // Focus next input
        if (e.target.nextSibling) {
            e.target.nextSibling.focus();
        } else if (!e.target.nextSibling && e.target.value) {
            handleSubmit([
                ...otp.map((item, idx) =>
                    idx === index ? e.target.value : item
                ),
            ]);
        }
        // Focus delete input
        if (e.target.value === "") {
            e.target.previousSibling.focus();
            if (index === 1) {
                setOtp([...otp.map((item: any, i: number) => "")]);
            }
        }
    };

    async function handleCheckin(props: any) {
        setLoading(true);
        let values = {
            ...dataCheckin,
            code: valueOTP,
            telephone: valuePhone,
            subdomain: SUBDOMAIN,
        };
        props &&
            (values = {
                ...dataCheckin,
                code: props.otp,
                subdomain: SUBDOMAIN,
                telephone: valuePhone,
            });
        try {
            const res = await authenApi.postAuthen(values);
            const action = res?.data.context;
            dispatch(postAuth(action));
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const handleSubmit = (props: any) => {
        if (valueOTP.length > 0) {
            if (counter !== 0) {
                let otp = props.join("") || valueOTP;
                setLoading(true);
                window.confirmationResult
                    .confirm(otp)
                    .then((result: any) => {
                        onClose();
                        handleCloseOTP();
                        otp
                            ? handleCheckin({ otp: otp })
                            : handleCheckin(false);
                        setLoading(false);
                    })
                    .catch((error: any) => {
                        console.log(error);
                        let errorCode = error.code;
                        setLoading(false);
                        if (errorCode === "auth/invalid-verification-code") {
                            // sai otp
                            setOpenDialogErrorIvalidCode(true);
                        } else if (errorCode === "auth/code-expired") {
                            // otp het han
                            setOpenDialogErrorExpiredCode(true);
                        } else {
                            console.log("Success");
                        }
                    });
            } else {
                setOpenDialogErrorExpiredCode(true);
            }
        } else {
            setOpenDialogEmptyNumber(true);
        }
    };

    useEffect(() => {
        const timer: any =
            counter > 0 &&
            open === true &&
            setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter, open]);

    return (
        <>
            <Dialog onClose={onClose} open={open}>
                <div
                    style={{ padding: "24px", borderRadius: "16px" }}
                    className="dialog-OTP "
                >
                    <div onClick={onClose} className="dialog-detail__close">
                        <img src={icon.increase} alt="" />
                    </div>
                    <div className="dialog-OTP__wrap">
                        <div className="dialog-OTP__img">
                            <img src={img.codeSnippet} alt="" />
                        </div>
                        <div className="dialog-OTP__content">
                            <p className="item-title">OTP Verification</p>
                            <p className="item-desc">
                                Mã xác thực đã gửi tới số{" "}
                                <span
                                    style={{
                                        color: "var(--purple)",
                                    }}
                                >
                                    {valuePhone}
                                </span>{" "}
                                vui lòng kiểm tra tin nhắn...{" "}
                                {counter === 0 ? (
                                    <span
                                        style={{ margin: "0px" }}
                                        className="text-error"
                                    >
                                        OTP hết hạn
                                    </span>
                                ) : (
                                    <span
                                        style={{
                                            color: "var(--purple)",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        00:
                                        {counter < 10 ? `0${counter}` : counter}
                                        s
                                    </span>
                                )}
                            </p>
                            <div className="wrap-input">
                                {otp.map((data, index) => {
                                    return (
                                        <input
                                            className="input-OTP"
                                            type="number"
                                            name="otp"
                                            maxLength={1}
                                            key={index}
                                            value={data}
                                            onChange={(e) =>
                                                handleChange(e, index)
                                            }
                                            onFocus={(e) => e.target.select()}
                                        />
                                    );
                                })}
                            </div>

                            <div className="flex-row gap-row-16">
                                <button
                                    className="btn-clear"
                                    onClick={(e) =>
                                        setOtp([...otp.map((v) => "")])
                                    }
                                >
                                    Xoá
                                </button>
                                {counter === 0 ? (
                                    <>
                                        <button
                                            className="btn-very"
                                            onClick={() => {
                                                handleRecaptcha(
                                                    valuePhone,
                                                    true
                                                );
                                                // onClose();
                                                setCounter(59);
                                                setOtp([...otp.map((v) => "")]);
                                            }}
                                        >
                                            Gửi lại OTP
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            style={
                                                loading === true
                                                    ? {
                                                          opacity: "0.5",
                                                          pointerEvents: "none",
                                                      }
                                                    : { opacity: "1" }
                                            }
                                            type="submit"
                                            className="btn-very"
                                            onClick={() => handleSubmit(false)}
                                        >
                                            {loading === true ? (
                                                <div className="lds-dual-ring"></div>
                                            ) : (
                                                `Xác nhận`
                                            )}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <DialogAlert
                title={`Mã xác thực chưa chính xác, vui lòng thử lại ...`}
                open={openDialogErrorIvalidCode}
                onClose={handleCloseDialogErrorIvalidCode}
                titleClose={`Thử lại`}
                imageError
            />
            <DialogAlert
                title={`Mã OTP hết hạn. Vui lòng gửi lại mã xác thực để thử lại.`}
                open={openDialogErrorExpiredCode}
                onClose={handleCloseDialogErrorExpiredCode}
                titleClose={`Thử lại`}
                imageError
            />
            <DialogAlert
                title={`Vui lòng nhập mã xác thực (OTP)`}
                open={openDialogEmptyNumber}
                onClose={handleCloseDialogEmptyNumber}
            />
        </>
    );
}
