import axiosClient from "./axiosClient";
class Organization {
    getOrg = (sub: string) => {
        const subDomain = sub ? sub : "/";
        const url = `/organizations/${subDomain}`;
        return axiosClient.get(url);
    };

    // API SERVICE
    // Lấy cate của dịch vụ theo id Org
    getServiceCateByOrg = (orgId: number) => {
        const id = orgId;
        const params: any = {
            include: "servicesCount",
            "filter[is_momo_ecommerce_enable]": "true",
        };
        const url = `/organizations/${id}/service_categories`;
        return axiosClient.get(url, { params });
    };
    // Lấy item của dịch vụ theo cate
    getServicesByCateId = (orgId: any, cate_id: any, page: number) => {
        const id = orgId;
        const params = {
            page: page,
            limit: "15",
            // ID cateOrg
            "filter[service_group_id]": cate_id,
        };
        const url = `/organizations/${id}/services`;
        return axiosClient.get(url, { params });
    };
    // Lấy tất cả item của dịch vụ spa
    getAllServicesByCateId = (orgId: any, page: number) => {
        const id = orgId;
        const params = {
            page: page,
            limit: "15",
        };
        const url = `/organizations/${id}/services`;
        return axiosClient.get(url, { params });
    };
    // Lấy item của dịch vụ theo keyWord
    getServiceByKeyword = (org_id: any, values: any) => {
        const url = `/organizations/${org_id}/services`;
        const params = {
            page: values.page,
            limit: 15,
            "filter[keyword]": values.keyword,
        };
        return axiosClient.get(url, { params });
    };
    // Lấy tất cả item giảm giá của dịch vụ
    // getAllServiceSpecialByCateId = (orgId: any, cate_id: any) => {
    //     const id = orgId;
    //     const params = {
    //         page: "1",
    //         limit: "15",
    //         "filter[service_group_id]": cate_id,
    //         "filter[special_price]": "true",
    //     };
    //     const url = `v1/organizations/${id}/services`;
    //     return axiosClient.get(url, { params });
    // };
    // END SERVICE

    // API API PRODUCT
    getProductByCateOrg = (orgId: number) => {
        const id = orgId;
        const params: any = {
            include: "productsCount",
            "filter[is_momo_ecommerce_enable]": "true",
        };
        const url = `/organizations/${id}/product_categories`;
        return axiosClient.get(url, { params });
    };
    getProductByCateId = (orgId: any, cate_id: any, page: number) => {
        const id = orgId;
        const params = {
            page: page,
            limit: "15",
            // ID cateOrg
            "filter[product_category_id]": cate_id,
        };
        const url = `/organizations/${id}/products`;
        return axiosClient.get(url, { params });
    };
    getAllProductByCateId = (orgId: any, page: number) => {
        const id = orgId;
        const params = {
            page: page,
            limit: "15",
        };
        const url = `/organizations/${id}/products`;
        return axiosClient.get(url, { params });
    };
    // Lấy item Product theo keyWord
    getProductByKeyword = (org_id: any, values: any) => {
        const url = `/organizations/${org_id}/products`;
        const params = {
            page: values.page,
            limit: 15,
            "filter[keyword]": values.keyword,
        };
        return axiosClient.get(url, { params });
    };
    // END API PRODUCT

    // API COMBO
    getComboByOrg = (orgId: number, page: number) => {
        const id = orgId;
        const params = {
            page: page,
            limit: "15",
            include: "products|services",
        };
        const url = `/organizations/${id}/treatment_combo`;
        return axiosClient.get(url, { params });
    };
    // END API COMBO
}
const organizationAPI = new Organization();
export default organizationAPI;
