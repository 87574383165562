import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import TabServiceTabList from "./Components/ServiceTabList";
import TabService from "./Components/TabService";
import TabServiceProduct from "./Components/TabProduct";
import TabServiceCombo from "./Components/TabCombo";
import "./service.scss";
import TabServiceCart from "./Components/TabCart";
import { useLocation } from "react-router-dom";
import ButtonCart from "../../components/ButtonCart/ButtonCart";

export default function Service() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(1);
    const title = "Chọn dịch vụ";
    const chooseTabClick = (id: any) => {
        setActiveTab(id);
    };
    const queryString = location.search.slice(1, 2);
    return (
        <div className="service">
            <ButtonCart queryString={queryString} />
            <Header search={true} title={title} />
            <Container>
                <div className="service-wrap">
                    <div className="service-tab">
                        {/* tab list menu */}
                        <TabServiceTabList
                            activeTab={activeTab}
                            chooseTabClick={chooseTabClick}
                        />
                        <div className="service-tab__content">
                            {/* tab service */}
                            <TabService
                                queryString={queryString}
                                activeTab={activeTab}
                            />
                            {/* tab product */}
                            <TabServiceProduct activeTab={activeTab} />
                            {/* tab combo */}
                            <TabServiceCombo activeTab={activeTab} />
                        </div>
                    </div>
                    <TabServiceCart />
                </div>
            </Container>
        </div>
    );
}
