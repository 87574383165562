import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import icon from "../../constants/icon";
import "./buttonCart.scss";
import DialogCart from "../../features/CheckIn/Components/DialogCart";
export default function ButtonCart(props: any) {
    const { queryString } = props;
    const [openDialogCart, setOpenDialogCart] = useState(false);

    const handleOpenDialogCart = () => {
        setOpenDialogCart(true);
    };
    const handleCloseDialogCart = () => {
        setOpenDialogCart(false);
    };
    const cartStore = useSelector((state: any) => state.carts);

    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (queryString == 1) {
            setOpenDialogCart(true);
        }
    }, [queryString]);

    return (
        <>
            {cartStore.cartList.length > 0 ? (
                <>
                    <div
                        onClick={(e) => {
                            handleOpenDialogCart();
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        className="btn-cart"
                    >
                        <img src={icon.shoppingCartWhite} alt="" />
                        <p>{cartStore.cartQuantity}</p>
                    </div>
                </>
            ) : null}
            <DialogCart open={openDialogCart} onClose={handleCloseDialogCart} />
        </>
    );
}
